<template>
  <div class="menu">
    <template v-for="item in menuItems" :key="item.name">
      <router-link
        v-if="item.routeName"
        v-haptic="'soft'"
        :class="{
          'menu-item': true,
          'menu-item--active': item.activeWith?.includes(route.name as PageName),
        }"
        active-class="menu-item--active"
        :to="{ name: item.routeName }"
      >
        <img :src="'/img/icons/menu/' + item.icon" :alt="item.name" />
        <span>
          {{ item.name }}
        </span>
      </router-link>
      <div class="menu-item" v-else>
        <img :src="'/img/icons/menu/' + item.icon" :alt="item.name" />
        <span>
          {{ item.name }}
        </span>
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { PageName } from '@/types';
import { useRoute } from 'vue-router';

const route = useRoute();

const menuItems = computed(() => [
  { name: 'Main', icon: 'main.svg', routeName: PageName.Tap },
  {
    name: 'Earn',
    icon: 'earn.svg',
    routeName: PageName.Earn,
  },
  {
    name: 'Quests',
    icon: 'tasks.svg',
    routeName: PageName.World,
    activeWith: [PageName.Location],
  },
  // { name: 'Soon', icon: 'soon.svg' },
]);
</script>

<style lang="scss" scoped>
.menu {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: 94vw;
  bottom: 3vw;
  padding: 1.5vw;
  margin: 0 3vw;
  background: rgba(black, 0.3);
  backdrop-filter: blur(5vw);
  border-radius: 4vw;
  border: 1px solid rgba(white, 0.2);
  z-index: 1000;

  &-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 28vw;
    padding: 2vw 0 1vw 0;
    border-radius: 4vw;
    font-size: 3vw;
    border: 1px transparent solid;
    text-decoration: none;

    &--active {
      background: #212121;
      border: 1px #2e2e2e solid;
    }

    img {
      width: 7vw;
    }

    &:not(&--active) {
      img {
        opacity: 0.3;
      }
      a,
      span {
        color: #71716d;
      }
    }

    span {
      color: white;
      font-size: 3.3vw;
      font-weight: 400;
      margin-top: 1.5vw;
      text-align: center;
      user-select: none;
      text-decoration: none;
    }
  }
}
</style>
