<template>
  <canvas ref="canvas" width="600" height="1300"></canvas>
</template>

<script setup lang="ts">
import { ref, onMounted, defineExpose } from 'vue';
import { Rive } from '@rive-app/canvas';

const canvas = ref<HTMLCanvasElement | null>(null);
let rive: Rive | null = null;

const play = () => {
  rive?.play('Upgrade', true);
};

defineExpose({
  play,
});

onMounted(() => {
  if (canvas.value) {
    rive = new Rive({
      src: '/animations/coin.riv',
      canvas: canvas.value,
      onLoad: () => {
        rive?.resizeDrawingSurfaceToCanvas();
      },
    });
  }
});
</script>
