<template>
  <svg
    width="12"
    height="13"
    viewBox="0 0 12 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 12.2847C9.31371 12.2847 12 9.59838 12 6.28467C12 2.97096 9.31371 0.284668 6 0.284668C2.68629 0.284668 0 2.97096 0 6.28467C0 9.59838 2.68629 12.2847 6 12.2847ZM6 2.28466C6.41421 2.28466 6.75 2.62044 6.75 3.03466V6.58467C6.75 6.99888 6.41421 7.33467 6 7.33467C5.58579 7.33467 5.25 6.99888 5.25 6.58467V3.03466C5.25 2.62044 5.58579 2.28466 6 2.28466ZM6.90156 9.28464C6.90156 9.7817 6.49862 10.1846 6.00156 10.1846C5.50451 10.1846 5.10156 9.7817 5.10156 9.28464C5.10156 8.78759 5.50451 8.38464 6.00156 8.38464C6.49862 8.38464 6.90156 8.78759 6.90156 9.28464Z"
    />
  </svg>
</template>
