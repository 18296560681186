import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Buffer } from 'buffer';
import store from '../store';
import { wait } from '.';
import type {
  AppVersion,
  HealthCheck,
  ResponseReferralCode,
  ResponseReferrals,
  ResponseDateTime,
  ResponseTasks,
  ResponseTask,
  ResponseUser,
  RegisterUser,
  User,
} from '@/types/api';
import type { TaskId } from '@/types/tasks';
import { REFERRAL_TIERS } from '@/const';

const apiClient: AxiosInstance = axios.create();

const MOCK_API_VERSION = '1.0.0';
const MOCK_USER: User = {
  check_in_last_timestamp: new Date(new Date().getTime() - 24 * 60 * 60 * 1000)
    .toISOString()
    .replace('Z', ''),
  // check_in_last_timestamp: '1970-01-01T00:00:00',
  check_in_streak: 27,
  discord_account: '',
  last_tap_timestamp: '2024-07-30T12:34:56.789',
  max_referrals_count: 75,
  referral_tier: 4,
  referrals_count: 33,
  registration_timestamp: '2024-07-30T12:34:56.789',
  score: 0,
  score_coefficient: 0,
  sora_account_id: '88888888888',
  telegram_id: 9999999999,
  tg_first_name: 'Tg first name',
  tg_last_name: 'Tg last name',
  tg_username: 'Tg username',
  x_account: 'X account',
};

const referralCodeResponseMock: ResponseReferralCode = {
  api_version: MOCK_API_VERSION,
  value: 'TEST_REFERRAL_CODE',
};

const getUserResponseMock = (): ResponseUser => {
  return {
    api_version: MOCK_API_VERSION,
    value: {
      ...MOCK_USER,
    },
  };
};

const referralsResponseMock: ResponseReferrals = {
  api_version: MOCK_API_VERSION,
  value: [
    {
      referral_id: 2387234828,
      referrer_total_score: 46,
    },
    {
      referral_id: 2387334825,
      referrer_total_score: 23,
    },
    {
      referral_id: 2387434826,
      referrer_total_score: 12,
    },
    {
      referral_id: 2387534827,
      referrer_total_score: 4,
    },
    {
      referral_id: 2387634828,
      referrer_total_score: 1,
    },
    {
      referral_id: 2387734825,
      referrer_total_score: 43,
    },
    {
      referral_id: 2387834826,
      referrer_total_score: 455,
    },
    {
      referral_id: 2387934827,
      referrer_total_score: 323,
    },
    {
      referral_id: 2388034828,
      referrer_total_score: 44,
    },
    {
      referral_id: 2388134825,
      referrer_total_score: 4,
    },
    {
      referral_id: 2388234826,
      referrer_total_score: 344,
    },
    {
      referral_id: 2388334827,
      referrer_total_score: 32,
    },
    {
      referral_id: 2388434828,
      referrer_total_score: 0,
    },
    {
      referral_id: 2388534825,
      referrer_total_score: 45,
    },
    {
      referral_id: 2388634826,
      referrer_total_score: 0,
    },
    {
      referral_id: 2388734827,
      referrer_total_score: 0,
    },
    {
      referral_id: 2388834828,
      referrer_total_score: 323,
    },
    {
      referral_id: 2388934825,
      referrer_total_score: 34,
    },
    {
      referral_id: 2389034826,
      referrer_total_score: 0,
    },
    {
      referral_id: 2389134827,
      referrer_total_score: 0,
    },
    {
      referral_id: 2389234828,
      referrer_total_score: 23,
    },
    {
      referral_id: 2389334825,
      referrer_total_score: 0,
    },
    {
      referral_id: 2389434826,
      referrer_total_score: 45,
    },
    {
      referral_id: 2389534827,
      referrer_total_score: 23,
    },
    {
      referral_id: 2389634828,
      referrer_total_score: 0,
    },
    {
      referral_id: 2389734825,
      referrer_total_score: 5621,
    },
  ],
};

const completedTasksMock = [1].map(id => ({
  task_id: id,
  choice: 1,
})) as ResponseTasks['value'];

function stringToBase64(str: string) {
  const encoder = new TextEncoder();

  // Encode the string to UTF-8
  const encoded = encoder.encode(str);

  // Convert Uint8Array to a binary string
  let binary = '';
  const len = encoded.byteLength;
  for (let i = 0; i < len; i++) {
    binary += String.fromCharCode(encoded[i]);
  }

  // Encode the binary string to Base64
  return btoa(binary);
}

apiClient.interceptors.request.use(
  config => {
    const baseApiUrl = store().baseApiUrl;
    const checkString = store().checkString;
    const hash = store().hash;
    console.info('baseApiUrl:', baseApiUrl);
    console.info('checkString:', checkString);
    console.info('hash:', hash);

    if (baseApiUrl) {
      config.baseURL = baseApiUrl;
    }

    if (checkString) {
      config.headers['sora-tg-data-check-string'] = stringToBase64(checkString);
    }

    if (hash) {
      const buffer = Buffer.from(hash, 'hex');
      const base64String = buffer.toString('base64');
      config.headers['sora-tg-hash'] = base64String;
    }

    // Кодирование имени пользователя и пароля в base64
    const username = store().httpAuth?.username;
    const password = store().httpAuth?.password;
    if (username && password) {
      config.headers['Authorization'] =
        'Basic ' + Buffer.from(username + ':' + password).toString('base64');
    }

    return config;
  },
  error => {
    return Promise.reject(error as Error);
  },
);

type RequestParams = Record<string, unknown>;
type RequestBody = Record<string, unknown>;

const request = async <T>(
  method: 'get' | 'post',
  url: string,
  params?: RequestParams,
  data?: RequestBody,
  config?: AxiosRequestConfig,
): Promise<AxiosResponse<T>> => {
  const response = await apiClient.request<T>({
    method,
    url,
    params,
    data,
    ...config,
  });
  return response;
};

function getTelegramId(): number | null {
  return store().telegramId ?? null;
}

// Health Check
export const fetchHealthCheck = async (): Promise<HealthCheck> => {
  const response = await request<HealthCheck>('get', '/health');
  return response.data;
};

// Get API Version
export const fetchApiVersion = async (): Promise<AppVersion> => {
  const response = await request<AppVersion>('get', '/version');
  return response.data;
};

// Get Referral Code
export const fetchReferralCode = async (): Promise<ResponseReferralCode> => {
  if (store().useMockData) {
    await wait(300);
    return referralCodeResponseMock;
  }
  const response = await request<ResponseReferralCode>(
    'get',
    `/users/${getTelegramId()}/referral_codes`,
  );
  return response.data;
};

// Get Referrals
export const fetchReferrals = async (
  offset = 0,
  limit?: number,
): Promise<ResponseReferrals> => {
  if (store().useMockData) {
    await wait(300);
    const value = referralsResponseMock.value;
    const valuePaginated = value.slice(
      offset,
      offset + (limit ?? value.length),
    );
    return {
      ...referralsResponseMock,
      value: valuePaginated,
    };
  }
  const response = await request<ResponseReferrals>(
    'get',
    `/users/${getTelegramId()}/referrals`,
    { offset, limit },
  );
  return response.data;
};

// Tap
export const tap = async (): Promise<ResponseDateTime> => {
  if (store().useMockData) {
    await wait(300);
    MOCK_USER.last_tap_timestamp = new Date().toISOString().replace('Z', '');
    return {
      api_version: MOCK_API_VERSION,
      value: MOCK_USER.last_tap_timestamp,
    };
  }
  const response = await request<ResponseDateTime>(
    'post',
    `/users/${getTelegramId()}/tap`,
  );
  return response.data;
};

// Get Completed Tasks
export const fetchCompletedTasks = async (): Promise<ResponseTasks> => {
  if (store().useMockData) {
    await wait(300);
    return {
      api_version: MOCK_API_VERSION,
      value: completedTasksMock,
    };
  }
  const response = await request<ResponseTasks>(
    'get',
    `/users/${getTelegramId()}/tasks`,
  );
  return response.data;
};

// Complete Task
export const completeTask = async (
  task_id: TaskId,
  choice = 1,
): Promise<ResponseTask> => {
  if (store().useMockData) {
    await wait(300);
    completedTasksMock.push({ task_id, choice });
    return {
      api_version: MOCK_API_VERSION,
      value: {
        task_id,
        user_id: getTelegramId() ?? 0,
      },
    };
  }
  const response = await request<ResponseTask>(
    'post',
    `/users/${getTelegramId()}/tasks/${task_id}/${choice}`,
  );
  return response.data;
};

// Get User
export const fetchUser = async (): Promise<ResponseUser> => {
  if (store().useMockData) {
    await wait(300);
    return getUserResponseMock();
  }
  const response = await request<ResponseUser>(
    'get',
    `/users/${getTelegramId()}`,
  );
  return response.data;
};

// Register User
export const registerUser = async (
  userData: RegisterUser,
): Promise<ResponseUser> => {
  if (store().useMockData) {
    await wait(300);
    return getUserResponseMock();
  }
  const response = await request<ResponseUser>(
    'post',
    `/users/${getTelegramId()}`,
    {},
    userData as RequestBody,
  );
  return response.data;
};

export const setXAccount = async (
  account: string,
): Promise<ResponseDateTime> => {
  const response = await request<ResponseDateTime>(
    'post',
    `/users/${getTelegramId()}/x_account`,
    {},
    {
      x_account: account,
    },
  );
  return response.data;
};

export const setDiscordAccount = async (
  account: string,
): Promise<ResponseDateTime> => {
  const response = await request<ResponseDateTime>(
    'post',
    `/users/${getTelegramId()}/discord_account`,
    {},
    {
      discord_account: account,
    },
  );
  return response.data;
};

export const buyOneInvite = async (): Promise<ResponseDateTime> => {
  const response = await request<ResponseDateTime>(
    'post',
    `/users/${getTelegramId()}/buy_max_referrals`,
  );
  return response.data;
};

export const checkIn = async (): Promise<ResponseUser> => {
  if (store().useMockData) {
    await wait(300);
    MOCK_USER.check_in_last_timestamp = new Date()
      .toISOString()
      .replace('Z', '');
    const user = store().user;
    MOCK_USER.check_in_streak = user ? user.check_in_streak + 1 : 1;
    return getUserResponseMock();
  }
  const response = await request<ResponseUser>(
    'post',
    `/users/${getTelegramId()}/check-in`,
  );
  return response.data;
};

export const claimReferralReward = async (): Promise<ResponseUser> => {
  if (store().useMockData) {
    await wait(300);
    MOCK_USER.referral_tier =
      REFERRAL_TIERS.filter(referrals => MOCK_USER.referrals_count >= referrals)
        .length - 1;
    return getUserResponseMock();
  }
  const response = await request<ResponseUser>(
    'post',
    `/users/${getTelegramId()}/claim-referral-reward`,
  );
  return response.data;
};
