<template>
  <svg
    width="7"
    height="11"
    viewBox="0 0 7 11"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.20753 5.21689L0.843926 1.85329C0.492454 1.50182 0.492454 0.93197 0.843926 0.580498C1.1954 0.229027 1.76525 0.229027 2.11672 0.580498L6.11672 4.5805C6.46819 4.93197 6.46819 5.50182 6.11672 5.85329L2.11672 9.85329C1.76525 10.2048 1.1954 10.2048 0.843926 9.85329C0.492454 9.50182 0.492454 8.93197 0.843926 8.5805L4.20753 5.21689Z"
    />
  </svg>
</template>
