<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.51143 13.1026L10.478 19.0692C10.8109 19.4021 11.3506 19.4021 11.6835 19.0692C11.9907 18.7619 12.0144 18.2784 11.7544 17.9441L11.6835 17.8638L7.17177 13.3522H18.7521C19.1892 13.3522 19.5495 13.0232 19.5988 12.5993L19.6045 12.4999C19.6045 12.0627 19.2754 11.7025 18.8515 11.6532L18.7521 11.6475H7.17177L11.6835 7.13597C11.9907 6.8287 12.0144 6.34521 11.7544 6.01083L11.6835 5.93053C11.3762 5.62326 10.8927 5.59963 10.5583 5.85962L10.478 5.93053L4.51143 11.8971L4.42912 11.9924L4.36804 12.0873L4.32248 12.1833L4.29233 12.2731L4.27116 12.3733L4.26515 12.4238L4.26193 12.5162L4.26515 12.5759L4.27903 12.671L4.30417 12.7659L4.34162 12.8606L4.38622 12.9437L4.44315 13.0256C4.46436 13.0526 4.48716 13.0783 4.51143 13.1026L10.478 19.0692L4.51143 13.1026Z"
    />
  </svg>
</template>
