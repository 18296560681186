<template>
  <svg
    width="24"
    height="25"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.4886 11.8974L13.522 5.93081C13.1891 5.59793 12.6494 5.59793 12.3165 5.93081C12.0093 6.23807 11.9856 6.72157 12.2456 7.05595L12.3165 7.13624L16.8282 11.6478H5.24788C4.81075 11.6478 4.45048 11.9768 4.40124 12.4007L4.39551 12.5001C4.39551 12.9373 4.72456 13.2975 5.14848 13.3468L5.24788 13.3525H16.8282L12.3165 17.864C12.0093 18.1713 11.9856 18.6548 12.2456 18.9892L12.3165 19.0695C12.6238 19.3767 13.1073 19.4004 13.4417 19.1404L13.522 19.0695L19.4886 13.1029L19.5709 13.0076L19.632 12.9127L19.6775 12.8167L19.7077 12.7269L19.7288 12.6267L19.7348 12.5762L19.7381 12.4838L19.7348 12.4241L19.721 12.329L19.6958 12.2341L19.6584 12.1394L19.6138 12.0563L19.5569 11.9744C19.5356 11.9474 19.5128 11.9217 19.4886 11.8974L13.522 5.93081L19.4886 11.8974Z"
    />
  </svg>
</template>
