<template>
  <div
    v-if="streak !== null"
    v-haptic="'soft'"
    class="banner"
    @click="showModal = true"
  >
    <div
      :class="{
        banner__header: true,
        'banner__header--pending': !checkInAvailable,
      }"
    >
      <picture class="banner__image">
        <source srcset="img/scrolls.avif" type="image/avif" />
        <source srcset="img/scrolls.png" type="image/png" />
        <img src="img/scrolls.png" alt="Daily check-in" />
      </picture>
      <div class="banner__header-center">
        <div class="banner__title">Daily check-in</div>
        <div class="banner__next-reward">
          <span class="banner__next-reward-text">Next reward:</span>
          <span class="banner__next-reward-value">{{ nextReward }}</span>
          <CoinIcon class="banner__coin" />
        </div>
        <div v-show="streak > 0" class="banner__time">
          <CountdownTimer
            v-if="checkInAvailableTimestamp"
            :timestamp="checkInAvailableTimestamp"
            text-before="Wait"
            @time-update="setCheckInAvailableIn"
            :hide-on-complete="true"
          />
          <CountdownTimer
            v-if="checkInFailTimestamp"
            :timestamp="checkInFailTimestamp"
            text-after="left"
            @time-update="setCheckInFailIn"
            :hide-on-complete="true"
          />
        </div>
      </div>
      <div class="banner__arrow-button">
        <ArrowChevronRightIcon />
      </div>
    </div>
    <div class="banner__content">
      <div v-if="checkInAvailable" class="banner__text">
        You`re on your <span>{{ day }} day of check-ins.</span><br />It`s time
        to check-in now.
      </div>
      <div class="banner__progress">
        <div class="banner__progress-bar">
          <div
            :style="{ width: completedPart + '%' }"
            class="banner__progress-bar-line"
          ></div>
        </div>
        <div class="banner__progress-day">Day {{ day }}</div>
        <div class="banner__earned">
          {{ earnedText }}
          <CoinIcon class="banner__coin" />
        </div>
      </div>
    </div>
  </div>

  <SModal
    v-if="streak !== null"
    v-model:show="showModal"
    v-bind="modalSettings"
  >
    <SModalCard class="modal check-in-modal">
      <template #title>
        <div class="modal__header">
          <picture class="modal__image">
            <source srcset="img/scrolls.avif" type="image/avif" />
            <source srcset="img/scrolls.png" type="image/png" />
            <img src="img/scrolls.png" alt="Daily check-in" />
          </picture>
          <div class="modal__title">Daily check-in</div>
          <div class="modal__description">
            Check in every day and get rewards
          </div>
        </div>
      </template>
      <div
        :class="{ modal__content: true, 'modal__content--loading': loading }"
      >
        <div class="list">
          <div
            v-for="(reward, index) in CHECK_IN_REWARDS"
            :key="index"
            :class="{
              list__item: true,
              'list__item--current': day - 1 === index,
              'list__item--checked': streak > index,
              'list__item--combo-day': index % 7 === 6,
            }"
          >
            <div class="list__day">Day {{ index + 1 }}</div>
            <CoinIcon class="list__coin" />
            <div
              class="list__reward"
              :style="{ opacity: reward === 0 ? 0.3 : undefined }"
            >
              {{ formatReward(reward) }}
            </div>
            <CheckCircleIcon v-if="streak > index" class="list__check" />
          </div>
        </div>
        <BlackoutBottom class="blackout" />
        <button
          v-if="checkInAvailableTimestamp"
          class="check-in-button"
          :disabled="!checkInAvailable"
          @click="checkIn"
        >
          <CountdownTimer
            v-if="!checkInAvailable"
            :timestamp="checkInAvailableTimestamp"
            text-before="Come back in"
          />
          <span v-else> CheckIn </span>
        </button>
      </div>
      <div v-if="loading" class="loading">
        <LoadingSpinner class="loading__spinner" />
      </div>
    </SModalCard>
  </SModal>
</template>

<script setup lang="ts">
import { ref, computed, watch, onBeforeUnmount, nextTick } from 'vue';
import { SModal, SModalCard } from '@soramitsu-ui/ui';
import useStore from '@/store';
import LoadingSpinner from './LoadingSpinner.vue';
import ArrowChevronRightIcon from './icons/ArrowChevronRightIcon.vue';
import { CHECK_IN_REWARDS } from '@/const';
import CoinIcon from './icons/CoinIcon.vue';
import CheckCircleIcon from './icons/CheckCircleIcon.vue';
import BlackoutBottom from './BlackoutBottom.vue';
import CountdownTimer from '@/components/CountdownTimer.vue';

const store = useStore();

const showModal = ref(false);
const loading = ref(false);
const modalSettings = {
  closeOnOverlayClick: true,
  closeOnEsc: true,
  focusTrap: true,
  eager: false,
};
let checkInInterval: number | null = null;
let checkInAvailableTimestamp = computed(() => {
  if (!user.value) return null;
  const lastCheckIn = new Date(user.value.check_in_last_timestamp + 'Z');

  // Normalize the last check-in time to 00:00 UTC of that day
  const lastCheckInDate = new Date(
    Date.UTC(
      lastCheckIn.getUTCFullYear(),
      lastCheckIn.getUTCMonth(),
      lastCheckIn.getUTCDate(),
    ),
  );

  // Create a new Date for the next day's 00:00 UTC
  const nextCheckInDate = new Date(lastCheckInDate);
  nextCheckInDate.setUTCDate(nextCheckInDate.getUTCDate() + 1);

  return nextCheckInDate;
});
let checkInFailTimestamp = computed(() => {
  if (!checkInAvailable.value) return null;
  if (streak.value === 0) return null;
  if (!checkInAvailableTimestamp.value) return null;
  const failDate = new Date(checkInAvailableTimestamp.value);
  failDate.setUTCDate(failDate.getUTCDate() + 1);
  return failDate;
});
const checkInAvailable = computed(() => {
  return checkInAvailableIn.value !== null && checkInAvailableIn.value <= 0;
});
const checkInFailed = computed(() => {
  return checkInFailIn.value !== null && checkInFailIn.value <= 0;
});

const user = computed(() => store.user);
const checkInAvailableIn = ref<number | null>(null);
const checkInFailIn = ref<number | null>(null);
const streak = computed(() => user.value?.check_in_streak ?? null);
const day = computed(() =>
  streak.value !== null
    ? !checkInAvailable.value
      ? streak.value
      : streak.value + 1
    : 1,
);

const nextReward = computed(() =>
  streak.value !== null ? CHECK_IN_REWARDS[streak.value] : null,
);

const earned = computed(() =>
  streak.value !== null
    ? CHECK_IN_REWARDS.slice(0, streak.value).reduce((a, b) => a + b, 0)
    : null,
);

const maxEarn = computed(() => CHECK_IN_REWARDS.reduce((a, b) => a + b, 0));

const completedPart = computed(() =>
  streak.value !== null ? (streak.value / CHECK_IN_REWARDS.length) * 100 : 0,
);

const earnedText = computed(() => {
  const earnedVal = (earned.value ?? 0).toLocaleString('en');
  const max = maxEarn.value.toLocaleString('en');
  return `${earnedVal} / ${max} earned`;
});

const setCheckInAvailableIn = (value: number) => {
  checkInAvailableIn.value = value;
};
const setCheckInFailIn = (value: number) => {
  checkInFailIn.value = value;
};
const checkIn = async () => {
  try {
    loading.value = true;
    await store.checkIn();
  } finally {
    loading.value = false;
  }
};

const resetStreakOnConditions = () => {
  nextTick(() => {
    // If more than 24 hours have passed, reset the streak
    if (checkInFailed.value && streak.value !== null && streak.value > 0) {
      store.resetStreak();
      checkInFailIn.value = null;
    }

    // If the user has performed a check in for the last day and the next day begins, reset the streak
    if (
      checkInAvailable.value &&
      streak.value !== null &&
      streak.value >= CHECK_IN_REWARDS.length
    ) {
      store.resetStreak();
    }
  });
};

const formatReward = (reward: number) => {
  if (reward % 1000 === 0) {
    return `${reward / 1000}K`;
  }
  return reward;
};

watch(user, () => {
  resetStreakOnConditions();
  if (checkInInterval !== null) {
    clearInterval(checkInInterval);
  }
  checkInInterval = setInterval(() => {
    resetStreakOnConditions();
  }, 1_000);
});
onBeforeUnmount(() => {
  if (checkInInterval !== null) {
    clearInterval(checkInInterval);
  }
});
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 7vw;
  border-radius: 4vw;
  cursor: pointer;
  background: #1d1c1b;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 20vw;
    border-radius: 4vw 4vw 0 0;
    background: #e3232c;
    background-image: url(../assets/pattern.svg);

    &--pending {
      background: #31302e;
      background-image: url(../assets/pattern.svg);
    }

    &-center {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20vw;
    }
  }

  &__image {
    position: absolute;
    width: 20vw;
    left: -2vw;
    top: 1vw;
    transform: rotate(13.01deg);

    img {
      width: 100%;
    }
  }

  &__title {
    color: white;
    font-size: 4.25vw;
    font-weight: 600;
    line-height: 120%;
  }

  &__next-reward {
    display: flex;
    gap: 1vw;
    font-size: 3.5vw;
    font-weight: 500;
    &-text {
      color: rgba(white, 0.5);
    }
  }

  &__time {
    background: rgba(black, 0.2);
    border-radius: 1.5vw;
    font-size: 3vw;
    font-weight: 700;
    padding: 0.5vw 2vw;
    text-transform: uppercase;
  }

  &__arrow-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8vw;
    height: 8vw;
    margin: 3.5vw;
    background: rgba(black, 25%);
    border-radius: 50%;

    svg {
      height: 3.5vw;
      width: auto;
      max-width: 4vw;
      fill: white;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 2.5vw 4vw;
    background: #1d1c1b;
    border-radius: 0 0 4vw 4vw;
  }

  &__text {
    line-height: 140%;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgba(white, 0.5);

    span {
      color: white;
      font-weight: 700;
    }
  }

  &__progress {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4vw;

    &-bar {
      position: relative;
      width: 100%;
      height: 1.5vw;
      margin-bottom: 2vw;
      background: rgba(white, 0.1);
      border-radius: 1vw;
      overflow: hidden;

      &-line {
        height: 100%;
        border-radius: 1vw;
        background: linear-gradient(
          90deg,
          #e3232c 0%,
          #ff8f94 69.99%,
          #ffffff 99.5%
        );
      }
    }

    &-day {
      font-size: 3.5vw;
      font-weight: 700;
      color: white;
    }

    &-earned {
      font-size: 3.5vw;
      font-weight: 500;
    }
  }
}

.modal {
  background: #31302e;
  background-image: url(../assets/pattern.svg);

  &__header {
    position: relative;
  }

  &__image {
    display: inline-flex;
    width: 25vw;

    img {
      width: 100%;
    }
  }

  &__description {
    font-size: 3.5vw;
    color: rgba(white, 0.5);
  }

  &__content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 3vw;
    padding-bottom: 20vw;

    &--loading {
      opacity: 0;
    }
  }
}

.list {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5vw;
  padding: 2vw 0;

  &__item {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1vw 0 1.5vw;
    border-radius: 4vw;
    background: #161514;

    &--current,
    &--checked {
      background: #272423;
    }

    &--current {
      border: 1px solid white;
    }

    &--combo-day {
      background: #e3232c;
      background-image: url(../assets/pattern.svg);
      box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
    }
  }

  &__day {
    font-size: 3.5vw;
    font-weight: 500;
    color: white;
  }

  &__item--checked &__day {
    opacity: 0.5;
  }

  &__coin {
    width: 8vw;
    height: 8vw;
    margin: 2vw 0;
  }

  &__item--checked &__coin {
    opacity: 0.5;
  }

  &__reward {
    background: #e3232c;
    border-radius: 100vw;
    font-size: 3vw;
    font-weight: 600;
    padding: 0 2vw;
  }

  &__item--checked &__reward {
    background: rgba(white, 0.12);
    color: white;
  }
  &__item--current &__reward,
  &__item--combo-day &__reward {
    background: white;
    color: black;
  }

  &__check {
    position: absolute;
    width: 4.5vw;
    height: 4.5vw;
    top: -1vw;
    right: -1vw;
    border-radius: 100vw;
    background: #1d1c1b;
    fill: white;
  }
}

.blackout {
  position: absolute;
  width: 100%;
  height: 30vw;
  z-index: auto;
}

.check-in-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4vw 8vw;

  width: calc(100% - 6vw);
  bottom: 3vw;

  background: #e3232c;
  box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
  border-radius: 100vw;

  color: white;
  font-size: 4vw;
  font-weight: 700;
  border: 1px solid transparent;

  &:disabled {
    box-shadow: none;
    background: #1d1c1b;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }
}

.loading {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4.5vw;
  font-weight: 700;
  color: rgba(white, 0.5);

  &__spinner {
    width: 30vw;
    margin: 35vw 0 35vw;
  }
}
</style>

<style lang="scss">
.s-modal__root .check-in-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90vh;

  .s-modal-card__header {
    align-items: flex-start;
    background: #31302e;
    background-image: url(../assets/pattern.svg);

    .s-button {
      background-color: rgba(white, 0.2);

      svg {
        fill: white;
      }
    }
  }
  .s-modal-card__content {
    background: #1d1c1b;
    padding: 4vw 0;
    border-radius: 4vw 4vw 0 0;
  }
}
</style>
