<script setup lang="ts">
import { computed, defineProps } from 'vue';
import FlagIcon from './icons/FlagIcon.vue';
import { Location } from '@/types/tasks';
import { calculateViewBoxFromPath } from '@/utils';
import { useRouter } from 'vue-router';
import { PageName } from '@/types';
import useStore from '@/store';
import CheckCircleIcon from '@/components/icons/CheckCircleIcon.vue';

const props = defineProps<{
  location: Location;
}>();

const router = useRouter();
const store = useStore();

const titlePath = `M14.3362 0.616943H2.42349C1.10524 0.616943 0.234154 1.9873 0.793665 3.18093L3.90421 9.81674C4.13788 10.3152 4.13056 10.8933 3.88435 11.3857L0.894289 17.3658C0.295878 18.5626 1.16617 19.9708 2.50426 19.9708H14.3362C15.3304 19.9708 16.1362 20.7767 16.1362 21.7708V22.541C16.1362 23.5352 16.9421 24.341 17.9362 24.341H145.212C146.206 24.341 147.012 23.5352 147.012 22.541V21.7708C147.012 20.7767 147.818 19.9708 148.812 19.9708H160.245C161.603 19.9708 162.472 18.5227 161.833 17.3237L158.688 11.4264C158.413 10.9118 158.405 10.2961 158.666 9.77439L161.942 3.22193C162.541 2.02511 161.67 0.616943 160.332 0.616943H148.812C147.818 0.616943 147.012 1.42283 147.012 2.41694V2.875C147.012 3.86912 146.206 4.675 145.212 4.675H17.9362C16.9421 4.675 16.1362 3.86912 16.1362 2.875V2.41694C16.1362 1.42283 15.3304 0.616943 14.3362 0.616943Z`;

const style = computed(() => ({
  width: props.location.area.width + 'vw',
  left: props.location.area.coordinates[0] + 'vw',
  top: props.location.area.coordinates[1] + 'vw',
}));

const areaStyle = computed(() => ({
  width: props.location.area.width + 'vw',
}));

const infoStyle = computed(() => ({
  marginLeft: props.location.infoOffset[0] + 'vw',
  marginTop: props.location.infoOffset[1] + 'vw',
}));

const flagStyle = computed(() => ({
  width: props.location.flagWidth + 'vw',
}));

function openLocation() {
  router.push({
    name: PageName.Location,
    params: {
      locationId: props.location.id,
    },
  });
}

const locationCompletedTasks = computed(() => {
  return props.location.tasks.filter(task =>
    store.completedTasks?.some(t => t.task_id === task.id),
  );
});
</script>

<template>
  <div class="location" :style="style" @click="openLocation">
    <svg
      class="area"
      :viewBox="calculateViewBoxFromPath(location.area.path)"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      fill-opacity="0.2"
      stroke="white"
      :style="areaStyle"
    >
      <path :d="location.area.path" />
    </svg>
    <svg
      class="hover-area"
      :viewBox="calculateViewBoxFromPath(location.area.path)"
      xmlns="http://www.w3.org/2000/svg"
      fill="white"
      stroke="white"
      :style="areaStyle"
    >
      <path :d="location.area.path" />
    </svg>

    <div class="center">
      <div class="info" :style="infoStyle">
        <div class="flag">
          <FlagIcon :style="flagStyle" />
          <svg
            class="flag-logo"
            width="25"
            height="25"
            :viewBox="calculateViewBoxFromPath(location.iconPath)"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path :d="location.iconPath" />
          </svg>
        </div>

        <div class="title">
          <svg
            class="title-shadow"
            width="163"
            height="25"
            :viewBox="calculateViewBoxFromPath(titlePath)"
            fill="black"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path :d="titlePath" />
          </svg>
          <svg
            class="clip-path"
            width="163"
            height="25"
            :viewBox="calculateViewBoxFromPath(titlePath)"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <defs>
              <clipPath
                id="clip-path-333"
                clipPathUnits="objectBoundingBox"
                transform="scale(0.00613496932 0.04)"
              >
                <path :d="titlePath" />
              </clipPath>
            </defs>
          </svg>
          <div class="title-value">
            {{ location.title }}
          </div>
        </div>
        <div class="tasks">
          <CheckCircleIcon class="tasks-icon" />
          <div class="tasks-value">
            <span>{{ locationCompletedTasks.length }}</span
            >/{{ location.tasks.length }} quests
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.location {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.area {
  position: relative;
}

.hover-area {
  position: absolute;
  opacity: 0;
  transition: opacity 500ms;

  .location:hover & {
    opacity: 0.2;
  }
}

.center {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0;
  height: 0;
}

.info {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.flag {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: transform 500ms;

  .location:hover & {
    transform: translateY(-2vw);
  }

  &-logo {
    position: absolute;
    width: 60%;
    margin-bottom: 25%;
    fill: #595959;
  }
}

.title {
  position: relative;
  margin-top: 1vw;

  &-shadow {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 4vw;
    left: 0;
    opacity: 0.5;
    filter: blur(2vw);
    pointer-events: none;
  }

  &-value {
    padding: 1vw 6vw 0.35vw;
    font-size: 3.5vw;
    font-weight: 600;
    clip-path: url(#clip-path-333);
    background: rgba(0, 0, 0, 0.3);
    white-space: nowrap;
    backdrop-filter: blur(5vw);
    color: white;
  }
}

.clip-path {
  position: absolute;
  top: 0;
  left: 0;
  pointer-events: none;
}

.tasks {
  position: relative;
  display: flex;
  font-size: 3vw;
  font-weight: 500;

  &-icon {
    width: 3.75vw;
  }

  &-value {
    margin-left: 1vw;

    span {
      font-size: 3.75vw;
    }
  }
}
</style>
