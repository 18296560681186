<script setup lang="ts">
import { ref, computed } from 'vue';
import CoinAnimation from '@/components/CoinAnimation.vue';
import DoubleCoin from '@/components/DoubleCoin.vue';
import MagicTap from '@/components/MagicTap.vue';
import { TAP_INTERVAL } from '@/const';
import { useHapticFeedback } from '@/directives/haptic';
import useStore from '@/store';
import { tap } from '@/utils/api';
import CountdownTimer from '@/components/CountdownTimer.vue';

const store = useStore();

const tapAnimation = ref<InstanceType<typeof MagicTap> | null>(null);
const coinAnimation = ref<InstanceType<typeof CoinAnimation> | null>(null);
const isTapAvailable = computed(() => {
  return tapAvailableIn.value !== null && tapAvailableIn.value <= 0;
});
const tapAvailableIn = ref<number | null>(null);
const nextTapTimestamp = computed(() => {
  if (!user.value) return null; // Return null if user value is not available
  const lastTap = new Date(user.value.last_tap_timestamp + 'Z');
  return new Date(lastTap.getTime() + TAP_INTERVAL * 1000);
});
const infoText =
  'Collect points to create Samurai \n and start your SORATOPIA journey.';

const user = computed(() => store.user);

const tapText = computed(() => {
  return 'Tap to pray';
});
const setIsCountTimer = (value: number) => {
  tapAvailableIn.value = value;
};
const tapAction = async () => {
  if (tapAvailableIn.value === null || tapAvailableIn.value > 0 || !user.value)
    return;
  useHapticFeedback('success');

  user.value.last_tap_timestamp = (await tap()).value;
  tapAvailableIn.value = 0;
  tapAnimation.value?.play();

  await store.fetchUser();

  setTimeout(() => {
    coinAnimation.value?.play();
  }, 300);
};
</script>

<template>
  <div class="home">
    <div class="background">
      <picture>
        <source srcset="img/background.avif" type="image/avif" />
        <source srcset="img/background.png" type="image/png" />
        <img src="img/background.png" alt="Background" />
      </picture>
    </div>
    <div class="animation-layer">
      <MagicTap ref="tapAnimation" />
      <CoinAnimation ref="coinAnimation" />
    </div>
    <div class="coin">
      <picture>
        <source srcset="img/coin.avif" type="image/avif" />
        <source srcset="img/coin.png" type="image/png" />
        <img src="img/coin.png" alt="Coin" />
      </picture>
      <span>{{ user?.score ?? '' }}</span>
    </div>
    <div class="info">{{ infoText }}</div>
    <div class="double-rewards">
      <DoubleCoin />
      <span class="double-rewards__text">
        2x rewards till SORATOPIA full launch
      </span>
    </div>
    <picture>
      <source srcset="img/temple.avif" type="image/avif" />
      <source srcset="img/temple.png" type="image/png" />
      <img
        class="temple"
        src="img/temple.png"
        alt="Temple"
        @click="tapAction"
      />
    </picture>
    <div
      v-if="nextTapTimestamp"
      class="tap-tooltip"
      :class="{
        'tap-tooltip--pending': !isTapAvailable,
      }"
      @click="tapAction"
    >
      <CountdownTimer
        :timestamp="nextTapTimestamp"
        text-before="Next time to pray in"
        @time-update="setIsCountTimer"
        :hide-on-complete="true"
      />
      <span v-if="isTapAvailable">
        {{ tapText }}
      </span>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.background {
  position: absolute;
  height: 100vh;
  overflow: hidden;

  img {
    width: 100vw;
    opacity: 0.1;
  }

  &:after {
    content: '';
    position: absolute;
    width: 100vw;
    height: 100%;
    left: 0;
    top: 0;

    background: linear-gradient(
      180deg,
      rgba(217, 217, 217, 0) 0%,
      rgba(169, 169, 169, 0.2) 41.3%,
      rgba(115, 115, 115, 0) 91.27%
    );
  }
}

.animation-layer {
  position: absolute;
  width: 100vw;
  top: -10vw;
  height: calc(100vh + 10vw);
  will-change: transform;
  overflow: hidden;
  pointer-events: none;

  canvas {
    position: absolute;
    width: 100vw;
    top: 0;
  }
}

.home {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
}

.coin {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 7vw;
  font-weight: 600;
  margin-top: 20vw;

  img {
    width: 10vw;
    margin-right: 3vw;
  }
}

.info {
  position: relative;
  margin-top: 2vw;
  color: #8b8b8b;
  font-size: 4vw;
  white-space: pre;
  text-align: center;
}

.temple {
  position: relative;
  width: 65vw;
  margin-top: 2vw;
  z-index: 1;
}

.tap-tooltip {
  position: relative;
  margin-top: 5vw;
  padding: 2vw 5vw;
  border-radius: 100vw;
  background: #e3232c;
  color: white;
  font-weight: 500;
  box-shadow: 0px 4.67191px 23.3596px rgba(227, 35, 44, 0.25);

  &--pending {
    background: #5e5e5e;
    box-shadow: 0px 4.67191px 23.3596px rgba(126, 126, 126, 0.25);
  }
}
.double-rewards {
  position: absolute;
  display: flex;
  font-size: 4vw;
  background-color: #302e2c;
  padding: 1vw 3vw;
  width: 100vw;
  align-items: center;
  justify-content: center;
  &__text {
    margin-left: 1vw;
  }
}
</style>
