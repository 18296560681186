<template>
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.8 1.8C13.8 0.805887 12.9941 0 12 0C11.0059 0 10.2 0.805887 10.2 1.8V10.2H1.8C0.805887 10.2 0 11.0059 0 12C0 12.9941 0.805887 13.8 1.8 13.8H10.2L10.2 22.2C10.2 23.1941 11.0059 24 12 24C12.9941 24 13.8 23.1941 13.8 22.2L13.8 13.8L22.2 13.8C23.1941 13.8 24 12.9941 24 12C24 11.0059 23.1941 10.2 22.2 10.2L13.8 10.2V1.8Z"
    />
  </svg>
</template>
