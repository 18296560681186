<template>
  <div class="double-coin">
    <picture>
      <source srcset="img/coin.avif" type="image/avif" />
      <source srcset="img/coin.png" type="image/png" />
      <img class="coin coin-left-bottom" src="img/coin.png" alt="Coin" />
    </picture>
    <picture>
      <source srcset="img/coin.avif" type="image/avif" />
      <source srcset="img/coin.png" type="image/png" />
      <img class="coin coin-right-top" src="img/coin.png" alt="Coin" />
    </picture>
  </div>
</template>

<style lang="scss" scoped>
.coin {
  height: 5vw;
  width: 5vw;
  border-radius: 50%;
}

.double-coin {
  position: relative;
  height: 6vw;
  width: 7vw;
}

.coin-left-bottom {
  position: absolute;
  bottom: 0;
  left: 0;
  border: 1px solid #302e2c;
  z-index: 1;
}

.coin-right-top {
  position: absolute;
  top: 0;
  right: 0;
  border: 1px solid transparent;
}
</style>
