export enum TaskType {
  Survey = 'survey',
  Subscription = 'subscription',
}

export enum StepType {
  Question = 'question',
  Info = 'info',
}

export enum SocialPlatform {
  Telegram = 'Telegram',
  X = 'X',
  Discord = 'Discord',
}

// Unique ID and Reward types
export type LocationId = number & { readonly brand: unique symbol };
export type TaskId = number & { readonly brand: unique symbol };
export type Reward = number & { readonly brand: unique symbol };

export function LocationId(value: number): LocationId {
  return value as LocationId;
}

export function TaskId(value: number): TaskId {
  return value as TaskId;
}

export function Reward(value: number): Reward {
  return value as Reward;
}

export interface Step {
  type: StepType;
  image: string | number | null;
  showReward: boolean;
}

export interface QuestionStep extends Step {
  type: StepType.Question;
  question: string;
  options: string[];
  answers: number[];
  chooseText: string | null;
  actionText: string | null;
}

export interface InfoStep extends Step {
  type: StepType.Info;
  title: string;
  description: string;
  continueText: string | null;
}

export type SurveyStep = QuestionStep | InfoStep;

export interface BaseTask {
  id: TaskId;
  type: TaskType;
  reward: Reward;
  title: string;
  image: string | number;
  beginText: string;
  multilineTitle: boolean;
  description: string;
}

export interface SurveyTask extends BaseTask {
  type: TaskType.Survey;
  steps: SurveyStep[];
  completedText: string | null;
}

export interface SubscriptionTask extends BaseTask {
  type: TaskType.Subscription;
  link: string;
  platform: SocialPlatform;
}

export type Task = SurveyTask | SubscriptionTask;

export interface Area {
  path: string;
  coordinates: [number, number];
  width: number;
}

export interface Location {
  id: LocationId;
  title: string;
  area: Area;
  infoOffset: [number, number];
  iconPath: string;
  flagWidth: number;
  tasks: Task[];
}

export type Locations = Location[];

export enum TasksListType {
  Map = 'map',
  List = 'list',
}
