import router from '@/router';
import { PageName } from '@/types';

type ConsoleKey = keyof typeof console;

export function disableDevTools(): void {
  document.addEventListener('contextmenu', function (event) {
    event.preventDefault();
  });
  Object.getOwnPropertyNames(console)
    .filter(function (property) {
      return typeof console[property as ConsoleKey] == 'function';
    })
    .forEach(function (verb) {
      console[verb as ConsoleKey] = function () {
        return 'Sorry, it`s disabled for security reasons...';
      };
    });
  window.addEventListener('devtools-opened', () => {
    router.push({ name: PageName.UnsupportedPlatform });
  });
  // TODO: think about this in the future
  // window.addEventListener('devtools-closed', () => {
  //   router.push({ name: PageName.Home });
  // });
  const verifyConsole = () => {
    const before = new Date().getTime();
    if (Function as unknown) {
      new Function('debugger')();
    }
    const after = new Date().getTime();
    if (after - before > 100) {
      // user had to resume the script manually via opened dev tools
      window.dispatchEvent(new Event('devtools-opened'));
    } else {
      window.dispatchEvent(new Event('devtools-closed'));
    }
    setTimeout(verifyConsole, 100);
  };
  verifyConsole();
}
