<template>
  <svg
    width="14"
    height="10"
    viewBox="0 0 14 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.4689 0.457485C11.8705 0.0558383 12.5277 0.0558383 12.9293 0.457485C13.331 0.859132 13.331 1.51628 12.9293 1.91793L5.72699 9.11432C5.32534 9.51597 4.67415 9.51597 4.2725 9.11432L0.672817 5.51464C0.27117 5.11299 0.27117 4.46179 0.672817 4.06014C1.07446 3.6585 1.72566 3.6585 2.12731 4.06014L4.99974 6.93258L11.4689 0.457485Z"
    />
  </svg>
</template>
