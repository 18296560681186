import type { Directive } from 'vue';

enum StatusValue {
  success = 'success',
  warning = 'warning',
  error = 'error',
}

type HapticFeedbackStatus = keyof typeof StatusValue;

export type HapticFeedbackBinding =
  | 'light'
  | 'medium'
  | 'heavy'
  | 'rigid'
  | 'soft'
  | HapticFeedbackStatus;

interface HTMLElementWithHandler extends HTMLElement {
  __handleClick__?: (event: Event) => void;
}

const StatusValues: string[] = Object.values(StatusValue);
const defaultType: HapticFeedbackBinding = 'medium';

function isStatus(value: HapticFeedbackBinding): value is HapticFeedbackStatus {
  return StatusValues.includes(value);
}

export function useHapticFeedback(type: HapticFeedbackBinding): void {
  const WebApp = window.Telegram?.WebApp;
  const HapticFeedback = WebApp?.HapticFeedback;
  if (!HapticFeedback) {
    console.warn('[SORATOPIA] Telegram WebApp HapticFeedback is not available');
    return;
  }
  if (isStatus(type)) {
    HapticFeedback.notificationOccurred?.(type);
  } else {
    HapticFeedback.impactOccurred?.(type);
  }
}

export const haptic: Directive<HTMLElementWithHandler, HapticFeedbackBinding> =
  {
    mounted(el, binding) {
      const type = binding.value || defaultType;
      const handleClick = () => useHapticFeedback(type);
      el.addEventListener('touchend', handleClick);
      // Store the listener so it can be removed later
      el.__handleClick__ = handleClick;
    },
    unmounted(el) {
      if (el.__handleClick__) {
        el.removeEventListener('touchend', el.__handleClick__);
        delete el.__handleClick__;
      }
    },
  };
