import { createApp } from 'vue';
import { createPinia } from 'pinia';
import { plugin } from '@soramitsu-ui/ui';
import { setInitDataFromDotEnv } from './utils';

import App from './App.vue';
import router from './router';
import { haptic } from './directives/haptic';

import '@/assets/styles';
import '@soramitsu-ui/ui/dist/style.css';

if (process.env.VUE_APP_INIT_DATA) {
  setInitDataFromDotEnv(process.env.VUE_APP_INIT_DATA);
}

const app = createApp(App);

app.directive('haptic', haptic);

app.use(plugin()).use(createPinia()).use(router).mount('#app');
