<template>
  <div class="login">
    <LoadingBackground :loading="state === State.Loading" />
    <div :class="{ content: true, 'content--hidden': state === State.Loading }">
      <h1>{{ title }}</h1>
      <div class="state-object">
        <CodeInput
          v-show="state === State.Input"
          ref="codeInput"
          @input="useCode"
        />
        <LoadingSpinner
          v-show="state === State.Pending"
          class="loading-spinner"
        />
        <img
          class="success-icon"
          v-show="state === State.Success"
          src="../assets/success.svg"
          alt="Success"
        />
        <img
          class="error-icon"
          v-show="state === State.Error"
          src="../assets/error.svg"
          alt="Error"
        />
      </div>

      <div class="info">{{ info }}</div>

      <button class="tg-chat-button" @click="openTgChat">
        Open official telegram chat
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import LoadingBackground from '@/components/LoadingBackground.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import CodeInput from '@/components/CodeInput.vue';
import useStore from '@/store';
import { PageName } from '@/types';
import { useHapticFeedback } from '@/directives/haptic';

enum State {
  Loading = 'loading',
  Input = 'input',
  Pending = 'pending',
  Success = 'success',
  Error = 'error',
}

const state = ref(State.Loading);
const codeInput = ref<typeof CodeInput>();
const router = useRouter();
const store = useStore();

const useCode = async (code: string) => {
  try {
    state.value = State.Pending;
    await store.registerUser(code);
    useHapticFeedback('success');
    state.value = State.Success;
  } catch (error) {
    useHapticFeedback('error');
    console.error(error);
    resetCode();
    state.value = State.Error;
  }
};

const resetCode = () => {
  codeInput.value?.resetCode();
};

const openTgChat = () => {
  window.open('https://t.me/soratopia', '_blank');
};

const title = computed(() => {
  return state.value === State.Success
    ? 'Samurai, you`ve been\n invited'
    : 'Samurai, reveal your\n hidden invitation';
});

const info = computed(() => {
  return state.value === State.Error
    ? 'You`re no true samurai! You`re a fraud.'
    : 'Find the secret invitation\nin the official telegram channel';
});

const loading = computed(() => store.loading);

watch(
  loading,
  newLoading => {
    state.value = newLoading ? State.Loading : State.Input;
  },
  { immediate: true },
);

watch(state, newState => {
  if (newState === State.Success) {
    setTimeout(() => {
      router.push({ name: PageName.Tap });
    }, 1000);
  } else if (newState === State.Error) {
    setTimeout(() => {
      state.value = State.Input;
      resetCode();
    }, 2000);
  }
});
</script>

<style lang="scss" scoped>
.content {
  position: relative;
  text-align: center;
  margin-top: 53.5vw;
  padding-top: 5vw;
  padding-bottom: 40vw;
  transition: opacity 1.5s ease;

  &--hidden {
    opacity: 0;
  }
}

.state-object {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 18vw;
  margin-top: 7vw;
}

.success-icon,
.error-icon {
  width: 18vw;
  height: 18vw;
}

.loading-spinner {
  width: 36vw;
  height: 36vw;
  margin: -9vw;
}

h1 {
  font-size: 9vw;
  font-weight: 700;
  line-height: 10vw;
  white-space: pre;
}

.info {
  color: #8b8b8b;
  font-size: 4vw;
  line-height: 5vw;
  white-space: pre;
  margin-top: 7vw;
  text-align: center;
}

.tg-chat-button {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4vw 8vw;
  z-index: 1000;

  width: 92vw;
  left: 4vw;
  bottom: 6vw;

  background: rgba(157, 129, 129, 0.3);
  border-radius: 100vw;

  color: white;
  font-size: 4vw;
  font-weight: 700;
}

@media (min-aspect-ratio: 2/3) {
  .tg-chat-button {
    position: relative;
    bottom: 0;
    margin-top: 8vw;
  }
}

.action-button {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4vw 8vw;
  margin-top: 4vw;
  z-index: 1000;

  width: 92vw;
  left: 4vw;
  bottom: 6vw;

  background: #e3232c;
  box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
  border-radius: 100vw;

  color: white;
  font-size: 4vw;
  font-weight: 700;
  border: 1px solid transparent;
}
</style>
