<template>
  <svg
    width="24"
    height="19"
    viewBox="0 0 24 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M23.701 10.0187C24.0645 10.3761 24.0972 10.943 23.7956 11.338L23.7132 11.4329L16.8506 18.4329C16.4895 18.8003 15.9154 18.8292 15.5207 18.5193L15.426 18.4347L11.2886 14.247C10.9005 13.8541 10.9043 13.2209 11.2972 12.8328C11.6598 12.4745 12.2272 12.4502 12.6177 12.7576L12.7114 12.8413L16.135 16.3069L22.2868 10.031C22.6739 9.63708 23.3071 9.6316 23.701 10.0187ZM6.5 12.7319C7.05228 12.7319 7.5 13.1796 7.5 13.7319C7.5 14.2842 7.05228 14.7319 6.5 14.7319H1.5C0.947715 14.7319 0.5 14.2842 0.5 13.7319C0.5 13.1796 0.947715 12.7319 1.5 12.7319H6.5ZM18.5 6.73193C19.0523 6.73193 19.5 7.17965 19.5 7.73193C19.5 8.28422 19.0523 8.73193 18.5 8.73193H1.5C0.947715 8.73193 0.5 8.28422 0.5 7.73193C0.5 7.17965 0.947715 6.73193 1.5 6.73193H18.5ZM18.5 0.731934C19.0523 0.731934 19.5 1.17965 19.5 1.73193C19.5 2.28422 19.0523 2.73193 18.5 2.73193H1.5C0.947715 2.73193 0.5 2.28422 0.5 1.73193C0.5 1.17965 0.947715 0.731934 1.5 0.731934H18.5Z"
    />
  </svg>
</template>
