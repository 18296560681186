<template>
  <picture class="coin-icon">
    <source srcset="img/coin.avif" type="image/avif" />
    <source srcset="img/coin.png" type="image/png" />
    <img src="img/coin.png" alt="Coin" />
  </picture>
</template>

<style lang="scss" scoped>
.coin-icon {
  display: inline-flex;
  width: 6vw;
  height: 6vw;
}
</style>
