<template>
  <div class="invite">
    <div class="background"></div>
    <div class="back-button" @click="goBack">
      <ArrowLeftIcon />
    </div>
    <div class="content">
      <picture>
        <source srcset="img/invite.avif" type="image/avif" />
        <source srcset="img/invite.png" type="image/png" />
        <img class="image" src="img/invite.png" alt="Invite" />
      </picture>
      <h1 class="title">{{ title }}</h1>
      <div
        :class="{
          'secret-code': true,
          'secret-code--disabled': referralsAvailable <= 0,
        }"
      >
        <div class="secret-code-label">Your secret code</div>
        <div class="secret-code-value">{{ referralCode }}</div>
        <button v-haptic="'soft'" class="copy-button" @click="copy">
          {{ copyText }}
        </button>
      </div>
      <div class="divider">OR</div>
      <a
        :class="{
          'tg-invite': true,
          'tg-invite--disabled': referralsAvailable <= 0,
        }"
        :href="referralsAvailable <= 0 ? undefined : tgInviteHref"
      >
        {{ tgInviteText }}
      </a>
      <BuyInvites :secondary="referralsAvailable > 0" />
      <ReferAndEarn :full-layout="false" />
      <ReferralsList class="referrals" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch } from 'vue';
import { useRouter } from 'vue-router';
import { INVITE_TEXT } from '@/const';
import useStore from '@/store';
import ReferralsList from '@/components/ReferralsList.vue';
import BuyInvites from '@/components/BuyInvites.vue';
import { PageName } from '@/types';
import { useHapticFeedback } from '@/directives/haptic';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import ReferAndEarn from '@/components/ReferAndEarn.vue';

const copied = ref(false);
const router = useRouter();
const store = useStore();

const user = computed(() => store.user);
const referralCode = computed(() => store.$state.referralCode || '');
const maxReferrals = computed(() => user.value?.max_referrals_count || 0);
const referralsCount = computed(() => user.value?.referrals_count || 0);
const referralsAvailable = computed(
  () => maxReferrals.value - referralsCount.value,
);

const title = computed(() => {
  if (!user.value) return 'Loading...';
  if (referralsAvailable.value <= 0) {
    return 'You`ve reached your\n invite limit, Samurai!';
  }
  return `Invite ${referralsAvailable.value} Samurais &\n Earn 10% of their Points`;
});

const copyText = computed(() => (copied.value ? 'Copied!' : 'Copy'));

const tgInviteText = 'Invite via telegram';

const tgInviteHref = computed(() => {
  const botUrl = `${store.tgBotUrl}/app?startapp=${referralCode.value}`;
  return `https://t.me/share/url?url=${encodeURIComponent(
    botUrl,
  )}&text=${encodeURIComponent(INVITE_TEXT)}`;
});

const copy = () => {
  navigator.clipboard.writeText(referralCode.value);
  copied.value = true;
  setTimeout(() => {
    copied.value = false;
  }, 2000);
};

const goBack = () => {
  useHapticFeedback('soft');
  router.push({ name: PageName.Earn });
};

watch(
  user,
  newValue => {
    if (newValue) {
      store.fetchReferralInfo();
    }
  },
  { immediate: true },
);
</script>

<style lang="scss" scoped>
$background: #1d1c1b;

.background {
  position: absolute;
  width: 100vw;
  height: 60vw;
  background: linear-gradient(180deg, rgba(45, 44, 42, 0) 0%, #2d2c2a 100%);
}

.back-button {
  position: fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 10vw;
  height: 10vw;
  background: rgba(#d9d9d9, 0.12);
  backdrop-filter: blur(5vw);
  top: 4vw;
  left: 4vw;
  border-radius: 100%;
  z-index: 1000;
  cursor: pointer;

  svg {
    width: 6vw;
    height: 6vw;
    fill: white;
  }
}

.invite {
  position: relative;
  width: 100vw;
  padding-bottom: 5vw;
}

.content {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  padding: 0 5vw;
}

.title {
  position: relative;
  margin-top: 5vw;
  white-space: pre;
  font-size: 7.5vw;
  text-align: center;
}

.image {
  position: relative;
  width: 40vw;
  margin-top: 25vw;
  z-index: 1;
}

.secret-code {
  position: relative;
  /* Input */

  box-sizing: border-box;
  display: flex;
  flex-direction: column;

  width: 100%;
  height: 15vw;
  padding: 2vw 5vw;

  background: $background;
  border: 1px solid #333333;
  border-radius: 100vw;

  margin-top: 8vw;
}

.secret-code-label {
  line-height: 100%;
  color: #8b8b8b;
}

.secret-code-value {
  line-height: 120%;
  font-size: 4.5vw;
  font-weight: 600;
  margin-top: 1vw;
  letter-spacing: -0.02em;

  .secret-code--disabled & {
    opacity: 0.2;
  }
}

.copy-button {
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 1vw 8vw;

  height: calc(11vw - 2px);
  top: 2vw;
  right: 2vw;

  background: #e3232c;
  box-shadow: 0 2.5vw 10vw rgba(227, 35, 44, 0.5);
  border: none;
  border-radius: 100vw;

  color: white;
  font-size: 4vw;
  font-weight: 700;

  .secret-code--disabled & {
    background: transparent;
    box-shadow: none;
    border: 1px solid #333333;
    color: rgba(255, 255, 255, 0.2);
  }
}

.divider {
  position: relative;
  display: flex;
  align-items: center;
  gap: 3vw;
  width: 100%;
  margin-top: 5vw;
  color: #b79e86;
  font-weight: 600;

  &::before,
  &::after {
    content: '';
    flex: 1;
    position: relative;
    height: 1px;
    background: #333333;
  }
}

.tg-invite {
  position: relative;
  /* Basic Button */

  /* Auto layout */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  width: 100%;
  height: 15vw;
  margin-top: 5vw;

  color: white;
  background: #5ea4dd;
  box-shadow: 0 1.5vw 7vw rgba(94, 164, 221, 0.5);
  border-radius: 100vw;

  font-size: 4.5vw;
  font-weight: 700;

  text-decoration: none;

  &--disabled {
    background: transparent;
    color: rgba(#5ea4dd, 0.5);
    border: 1px solid rgba(#5ea4dd, 0.5);
    box-shadow: none;
    cursor: not-allowed;
  }
}

.referrals {
  margin-top: 5vw;
}
</style>
