export async function wait(ms: number): Promise<void> {
  return new Promise(resolve => setTimeout(resolve, ms));
}

export function calculateViewBoxFromPath(d: string, strokeWidth = 1): string {
  const svg: SVGSVGElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'svg',
  );
  svg.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
  svg.setAttribute('width', '0');
  svg.setAttribute('height', '0');
  svg.style.position = 'absolute';
  svg.style.visibility = 'hidden';

  const path: SVGPathElement = document.createElementNS(
    'http://www.w3.org/2000/svg',
    'path',
  );
  path.setAttribute('d', d);
  path.setAttribute('stroke-width', strokeWidth.toString());
  svg.appendChild(path);

  document.body.appendChild(svg);

  const bbox: DOMRect = path.getBBox();
  const adjustedBbox = {
    x: bbox.x - strokeWidth / 2,
    y: bbox.y - strokeWidth / 2,
    width: bbox.width + strokeWidth,
    height: bbox.height + strokeWidth,
  };

  const viewBox = `${adjustedBbox.x} ${adjustedBbox.y} ${adjustedBbox.width} ${adjustedBbox.height}`;

  document.body.removeChild(svg);

  return viewBox;
}

export function setInitDataFromDotEnv(initData: string) {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  (window as any).Telegram = {
    WebApp: {
      initData,
    },
  };
}
