<script setup lang="ts">
import { ref, computed, onMounted, watch } from 'vue';
import { useRoute, useRouter } from 'vue-router';
import useStore from '@/store';
import { SocialPlatform, TaskId, TaskType } from '@/types/tasks';
import { completeTask, setDiscordAccount, setXAccount } from '@/utils/api';
import ArrowLeftIcon from '@/components/icons/ArrowLeftIcon.vue';
import LoadingSpinner from '@/components/LoadingSpinner.vue';
import WarningIcon from '@/components/icons/WarningIcon.vue';
import { SModal, SModalCard, STextField } from '@soramitsu-ui/ui';
import { useHapticFeedback } from '@/directives/haptic';
import {
  SOCIAL_PLATFORM_PREFIXES,
  SOCIAL_PLATFORM_USERNAME_REGEXP,
} from '@/const';
import { PageName } from '@/types';

enum TaskState {
  Begin = 'begin',
  Verify = 'verify',
  Completed = 'completed',
}

const route = useRoute();
const router = useRouter();
const store = useStore();

const state = ref(TaskState.Begin);
const showVerifyModal = ref(false);
const username = ref('');
const verifyLoading = ref(false);

const modalSettings = {
  closeOnOverlayClick: true,
  closeOnEsc: true,
  focusTrap: true,
  eager: false,
};

const task = computed(() =>
  store.getTaskById(Number(route.params.id) as TaskId),
);
const location = computed(() =>
  store.getLocationByTaskId(Number(route.params.id) as TaskId),
);

const coverSrc = computed(() => `/img/tasks/tasks/${task.value?.image}`);

const actionButtonDisabled = computed(
  () => state.value === TaskState.Completed,
);

const actionButtonText = computed(() => {
  if (state.value === TaskState.Begin) {
    return task.value?.beginText;
  } else if (state.value === TaskState.Verify) {
    return 'Verify quest completion';
  } else if (state.value === TaskState.Completed) {
    if (task.value?.type === TaskType.Subscription) {
      return 'Quest completed';
    } else {
      return task.value?.completedText;
    }
  }
  return '';
});

const actionButtonSecondary = computed(() => state.value === TaskState.Verify);

const completed = computed(() => {
  if (!task.value) {
    return false;
  }
  return (store.completedTasks ?? []).some(
    completedTask => completedTask.task_id === task.value?.id,
  );
});

const verifyButtonText = computed(() => {
  return username.value === ''
    ? 'Enter username'
    : verifyButtonDisabled.value
    ? 'Invalid username'
    : 'Verify';
});

const usernameWithPrefix = computed({
  get() {
    if (task.value?.type === TaskType.Subscription) {
      return SOCIAL_PLATFORM_PREFIXES[task.value.platform] + username.value;
    }
    return '';
  },
  set(value: string) {
    username.value = value.slice(1);
  },
});

const usernameInvalid = computed(() => {
  if (
    !task.value ||
    task.value.type !== TaskType.Subscription ||
    !username.value
  ) {
    return false;
  }
  return !SOCIAL_PLATFORM_USERNAME_REGEXP[task.value.platform].test(
    username.value,
  );
});

const verifyButtonDisabled = computed(() => {
  return username.value === '' || usernameInvalid.value;
});

const goBack = () => {
  useHapticFeedback('soft');
  const previousRoutePath = router.options.history.state.back;
  const previousRoute = previousRoutePath
    ? router.resolve(previousRoutePath.toString()).matched[0]
    : null;
  if (previousRoute?.name === PageName.Location) {
    router.back();
  } else {
    router.push({
      name: PageName.Location,
      params: { locationId: location.value?.id },
    });
  }
};

const verify = async () => {
  if (!task.value) return;
  verifyLoading.value = true;
  useHapticFeedback('soft');
  if (task.value.type === TaskType.Subscription) {
    if (task.value.platform === SocialPlatform.X) {
      await setXAccount(usernameWithPrefix.value);
    }
    if (task.value.platform === SocialPlatform.Discord) {
      await setDiscordAccount(usernameWithPrefix.value);
    }
  }
  await completeTask(task.value.id);
  await store.fetchCompletedTasks();
  await store.fetchUser();
  useHapticFeedback('success');
  state.value = TaskState.Completed;
  verifyLoading.value = false;
  showVerifyModal.value = false;
};

const performAction = async () => {
  if (!task.value) {
    useHapticFeedback('error');
    return;
  }

  if (state.value === TaskState.Begin) {
    useHapticFeedback('soft');
    if (task.value?.type === TaskType.Subscription) {
      const id = store.telegramId;
      if (!id) {
        return;
      }
      localStorage.setItem(`${id}.task${task.value.id}State`, TaskState.Verify);
      setTimeout(() => {
        state.value = TaskState.Verify;
      }, 1000);
      window.open(task.value.link, '_blank');
    } else {
      router.push({
        name: PageName.TaskSurvey,
        params: { id: task.value?.id, step: 0 },
      });
    }
  } else if (state.value === TaskState.Verify) {
    if (
      task.value.type === TaskType.Subscription &&
      task.value.platform === SocialPlatform.Telegram
    ) {
      verify();
    } else {
      useHapticFeedback('soft');
      showVerifyModal.value = true;
    }
  } else if (state.value === TaskState.Completed) {
    useHapticFeedback('soft');
    router.push({
      name: PageName.Location,
      params: { locationId: location.value?.id },
    });
  }
};

watch(
  () => state.value,
  () => {
    if (!task.value) {
      return;
    }
    const id = store.telegramId;
    if (!id) {
      return;
    }
    localStorage.setItem(`${id}.task${task.value.id}State`, state.value);
  },
);

watch(
  () => task.value,
  newTask => {
    if (!newTask) {
      return;
    }
    const id = store.telegramId;
    if (!id) {
      return;
    }
    const item = localStorage.getItem(`${id}.task${newTask.id}State`);
    const savedState = item === 'null' ? null : item;
    if (savedState) {
      state.value = savedState as TaskState;
    }
  },
);

onMounted(() => {
  let storageState: TaskState | null = null;
  const id = store.telegramId;
  if (task.value && id) {
    const item = localStorage.getItem(`${id}.task${task.value.id}State`);
    storageState = item === 'null' ? null : (item as TaskState);
  }
  state.value = completed.value
    ? TaskState.Completed
    : storageState || TaskState.Begin;

  const user = store.user;
  if (user && task.value?.type === TaskType.Subscription) {
    if (task.value.platform === SocialPlatform.X && store.user?.x_account) {
      usernameWithPrefix.value = user.x_account;
    } else if (
      task.value.platform === SocialPlatform.Discord &&
      user.discord_account
    ) {
      usernameWithPrefix.value = user.discord_account;
    } else if (task.value.platform === SocialPlatform.Telegram) {
      usernameWithPrefix.value = `${user.telegram_id}`;
    }
  }
});
</script>

<template>
  <div class="task-view">
    <div class="back-button" @click="goBack">
      <ArrowLeftIcon />
    </div>
    <div class="cover">
      <picture>
        <source :srcset="coverSrc + '.avif'" type="image/avif" />
        <source :srcset="coverSrc + '.jpg'" type="image/jpeg" />
        <img :src="coverSrc + '.jpg'" alt="Quest cover" />
      </picture>
      <div class="cover-blackout"></div>
    </div>
    <h1 class="title">
      {{ task?.title }}
    </h1>
    <div class="description">
      {{ task?.description }}
    </div>
    <div v-if="task?.type === TaskType.Subscription" class="warning">
      <WarningIcon /> Cheaters will face reward penalties
    </div>
    <div class="reward">
      <span class="reward-title">Total reward</span>
      <span class="reward-value">+{{ task?.reward }}</span>
      <picture>
        <source srcset="img/coin.avif" type="image/avif" />
        <source srcset="img/coin.png" type="image/png" />
        <img class="coin" src="img/coin.png" alt="Coin" />
      </picture>
    </div>

    <SModal
      v-if="task?.type === TaskType.Subscription"
      v-model:show="showVerifyModal"
      v-bind="modalSettings"
    >
      <SModalCard
        :title="`Enter your ${task.platform} username`"
        @close="state = TaskState.Begin"
      >
        <div class="modal-content">
          <div :class="{ content: true, 'content--loading': verifyLoading }">
            <STextField
              class="username-input"
              :class="{ 'username-input--invalid': usernameInvalid }"
              :label="`Your ${task.platform} username`"
              v-model="usernameWithPrefix"
            />
            <div v-if="task?.type === TaskType.Subscription" class="warning">
              <WarningIcon /> Cheaters will face reward penalties
            </div>
            <button
              class="verify-button"
              :disabled="verifyButtonDisabled"
              @click="verify"
            >
              {{ verifyButtonText }}
            </button>
          </div>
          <div v-if="verifyLoading" class="loading">
            <LoadingSpinner class="loading__spinner" />
            <span>Verification in process</span>
          </div>
        </div>
      </SModalCard>
    </SModal>
    <div class="action-button__wrapper">
      <LoadingSpinner
        v-if="verifyLoading"
        class="action-button__loading-spinner"
      />
      <button
        :style="{
          opacity: verifyLoading ? 0 : 1,
          pointerEvents: verifyLoading ? 'none' : 'auto',
        }"
        :class="{
          'action-button': true,
          'action-button--secondary': actionButtonSecondary,
        }"
        :disabled="actionButtonDisabled"
        @click="performAction"
      >
        {{ actionButtonText }}
      </button>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.task-view {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 46vw;

  .back-button {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 10vw;
    height: 10vw;
    background: #141414;
    top: 4vw;
    left: 4vw;
    border-radius: 100%;
    z-index: 1000;
    cursor: pointer;

    svg {
      width: 6vw;
      height: 6vw;
      fill: white;
    }
  }

  .cover {
    position: relative;
    width: 100%;
    height: 50vh;

    picture {
      display: block;
      height: 100%;
      padding-bottom: 2px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      vertical-align: bottom;
    }
  }

  .cover-blackout {
    position: absolute;
    height: 30vh;
    width: 100vw;
    left: 0;
    bottom: 0;
    background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #0e0e0e 100%);
  }

  .title {
    position: relative;
    width: 92vw;
    margin-top: -10vh;
    font-size: 8vw;
    line-height: 100%;
    text-align: center;
  }

  .description {
    position: relative;
    width: 92vw;
    margin-top: 4vw;
    font-size: 4vw;
    text-align: center;
    color: #8b8b8b;
  }

  .reward {
    position: relative;
    display: flex;
    align-items: center;
    width: 84vw;
    height: 10vw;
    padding: 0 2vw;
    margin-top: 8vw;
    border-radius: 3vw;
    background: #1d1c1b;
  }

  .reward-title {
    font-size: 4vw;
    font-weight: 400;
    padding: 2vw;
  }

  .reward-value {
    font-size: 4.5vw;
    font-weight: 400;
    background: linear-gradient(
      90deg,
      #c8a378 0%,
      #ffdcb3 55.32%,
      #f6d199 100%
    );
    background-clip: text;
    -webkit-text-fill-color: transparent;
    margin-left: auto;
    margin-right: 2vw;
  }

  .coin {
    width: 6vw;
    height: 6vw;
    margin-right: 2vw;
  }
}

.action-button,
.verify-button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4vw 0;
  z-index: 1000;

  background: #e3232c;
  box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
  border-radius: 100vw;

  color: white;
  font-size: 4vw;
  font-weight: 700;
  border: 1px solid transparent;

  &--secondary {
    background: white;
    box-shadow: 0 1vw 5vw rgba(255, 255, 255, 0.5);
    color: black;
  }

  &:disabled {
    background: #0e0e0e;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }
}

.action-button {
  width: 100%;

  &__wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    left: 4vw;
    bottom: 6vw;
    width: 92vw;
  }

  &__loading-spinner {
    position: absolute;
    height: 200%;
    top: -50%;
  }
}

.verify-button {
  width: 100%;
  margin-top: 8vw;
  &:disabled {
    background: transparent;
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}

.modal-header h3 {
  margin: 0;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.content--loading {
  opacity: 0;
}

.warning {
  margin-top: 5vw;
  color: #ff8686;
  text-align: center;

  svg {
    display: inline-block;
    width: 4vw;
    height: 4vw;
    fill: #ff8686;
  }
}

.loading {
  position: absolute;
  width: 100%;
  height: 30vw;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4.5vw;
  font-weight: 700;
  color: rgba(white, 0.5);

  &__spinner {
    width: 30vw;
  }
}
</style>

<style lang="scss">
.username-input {
  label {
    font-size: 3vw;
    color: gray !important;
  }
  .s-text-field__input-wrapper {
    border-radius: 3vw;
    color: white;
    background: #161514 !important;
    border: 1px solid transparent !important;
  }

  &:focus-within {
    .s-text-field__input-wrapper {
      border-color: white !important;
    }
  }

  &--invalid,
  &--invalid:focus-within {
    .s-text-field__input-wrapper {
      border-color: #e3232c !important;
      color: #e3232c;
    }
  }
}
</style>
