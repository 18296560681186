<template>
  <svg
    width="20"
    height="23"
    viewBox="0 0 20 23"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.0001 8.71408C11.0257 8.71408 11.8572 7.88261 11.8572 6.85693C11.8572 5.83126 11.0257 4.99979 10.0001 4.99979C8.9744 4.99979 8.14293 5.83126 8.14293 6.85693C8.14293 7.88261 8.9744 8.71408 10.0001 8.71408Z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3.50007 6.85693C3.50007 3.26708 6.41022 0.356934 10.0001 0.356934C13.5899 0.356934 16.5001 3.26708 16.5001 6.85693C16.5001 11.041 13.6789 14.51 12.0013 16.2189C10.8883 17.3526 9.11189 17.3526 7.99887 16.2189C6.32126 14.51 3.50007 11.041 3.50007 6.85693ZM10.0001 2.21408C7.43589 2.21408 5.35721 4.29275 5.35721 6.85693C5.35721 10.2621 7.70485 13.2684 9.32414 14.9178C9.70922 15.3101 10.2909 15.3101 10.676 14.9178C12.2953 13.2684 14.6429 10.2621 14.6429 6.85693C14.6429 4.29275 12.5643 2.21408 10.0001 2.21408Z"
    />
    <path
      d="M3.74349 13.8885C4.07727 14.2778 4.03221 14.8641 3.64286 15.1978C2.91674 15.8203 2.5715 16.5073 2.5715 17.0712C2.5715 17.8629 3.1601 18.775 4.54163 19.5425C5.89171 20.2925 7.81973 20.7855 10.0001 20.7855C12.1804 20.7855 14.1084 20.2925 15.4585 19.5425C16.84 18.775 17.4286 17.8629 17.4286 17.0712C17.4286 16.4917 17.1106 15.8506 16.3669 15.2254C15.9743 14.8954 15.9236 14.3096 16.2536 13.9171C16.5835 13.5245 17.1693 13.4738 17.5619 13.8038C18.5626 14.645 19.2858 15.7614 19.2858 17.0712C19.2858 18.8436 18.0036 20.253 16.3604 21.1659C14.6858 22.0963 12.4353 22.6426 10.0001 22.6426C7.56488 22.6426 5.31434 22.0963 3.63972 21.1659C1.99656 20.253 0.714355 18.8436 0.714355 17.0712C0.714355 15.7757 1.46625 14.6176 2.43415 13.7879C2.82351 13.4541 3.40972 13.4991 3.74349 13.8885Z"
    />
  </svg>
</template>
