<template>
  <div
    v-haptic="'soft'"
    :class="{ banner: true, 'banner--secondary': secondary }"
    @click="showModal = true"
  >
    <div class="banner__title">
      Buy more invites for
      <br />
      <picture class="banner__coin">
        <source srcset="img/coin.avif" type="image/avif" />
        <source srcset="img/coin.png" type="image/png" />
        <img src="img/coin.png" alt="Coin" />
      </picture>
      coins now
    </div>
    <button class="banner__button">Buy</button>
    <picture class="banner__image">
      <source srcset="img/samurai.avif" type="image/avif" />
      <source srcset="img/samurai.png" type="image/png" />
      <img src="img/samurai.png" alt="Banner" />
    </picture>
  </div>
  <SModal v-model:show="showModal" v-bind="modalSettings">
    <SModalCard title="Buy invites">
      <div class="modal-content">
        <div :class="{ content: true, 'content--loading': loading }">
          <div class="invite-selector">
            <button
              v-haptic="'soft'"
              :class="{
                'invite-selector__button': true,
                'invite-selector__button--disabled': inviteCount <= 1,
              }"
              @click="decreaseInviteCount"
            >
              <MinusIcon />
            </button>
            <div class="invite-selector__center">
              <div class="invite-selector__amount">{{ inviteCount }}</div>
              <span>Invite{{ inviteCount > 1 ? 's' : '' }}</span>
            </div>
            <button
              v-haptic="'soft'"
              :class="{
                'invite-selector__button': true,
              }"
              @click="increaseInviteCount"
            >
              <PlusIcon />
            </button>
          </div>
          <div class="invite-options">
            <button
              v-haptic="'soft'"
              v-for="option in INVITE_BUY_OPTIONS"
              :key="option"
              :class="{ active: inviteCount === option }"
              @click="selectOption(option)"
            >
              {{ option }}
            </button>
          </div>
          <div class="info">
            <div class="info__item" v-for="item in info" :key="item.title">
              <div class="info__title">{{ item.title }}</div>
              <div class="info__value">
                {{ item.value }}
              </div>
              <picture>
                <source srcset="img/coin.avif" type="image/avif" />
                <source srcset="img/coin.png" type="image/png" />
                <img class="info__coin" src="img/coin.png" alt="Coin" />
              </picture>
            </div>
          </div>
          <button
            class="buy-button"
            :disabled="balance < total || loading"
            @click="buyInvites"
          >
            {{ balance < total ? 'Not enough coins' : 'Buy now' }}
          </button>
        </div>
        <div v-if="loading" class="loading">
          <LoadingSpinner class="loading__spinner" />
          <span>Buying in process</span>
        </div>
      </div>
    </SModalCard>
  </SModal>
</template>

<script setup lang="ts">
import { ref, computed, defineProps } from 'vue';
import { SModal, SModalCard } from '@soramitsu-ui/ui';
import useStore from '@/store';
import { INVITE_COST, INVITE_BUY_OPTIONS } from '@/const';
import LoadingSpinner from './LoadingSpinner.vue';
import PlusIcon from './icons/PlusIcon.vue';
import MinusIcon from './icons/MinusIcon.vue';
import { buyOneInvite } from '@/utils/api';
import { wait } from '@/utils';
import { useHapticFeedback } from '@/directives/haptic';

defineProps({
  secondary: {
    type: Boolean,
    default: false,
  },
});

const store = useStore();

const showModal = ref(false);
const inviteCount = ref(1);
const loading = ref(false);

const modalSettings = {
  closeOnOverlayClick: true,
  closeOnEsc: true,
  focusTrap: true,
  eager: false,
};

const user = computed(() => store.user);
const balance = computed(() => user.value?.score || 0);
const total = computed(() => inviteCount.value * INVITE_COST);

const info = computed(() => [
  { title: 'Your balance', value: balance.value.toLocaleString('en-US') },
  { title: 'Total', value: total.value.toLocaleString('en-US') },
]);

const increaseInviteCount = () => {
  inviteCount.value += 1;
};

const decreaseInviteCount = () => {
  if (inviteCount.value > 1) {
    inviteCount.value -= 1;
  }
};

const selectOption = (option: number) => {
  inviteCount.value = option;
};

const buyInvites = async () => {
  if (balance.value >= total.value) {
    loading.value = true;
    try {
      for (let i = 0; i < inviteCount.value; i++) {
        await buyOneInvite();
        if (i < inviteCount.value - 1) {
          await wait(500);
        }
      }
      useHapticFeedback('success');
      showModal.value = false;
    } catch (error) {
      useHapticFeedback('error');
      console.error(error);
    } finally {
      await store.fetchUser();
      loading.value = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 7vw;
  background: #e3232c;
  padding: 3vw;
  border-radius: 3vw;
  cursor: pointer;
  overflow: hidden;

  &--secondary {
    background: #1d1c1b;
  }

  &__title {
    color: white;
    font-size: 5vw;
    font-weight: 600;
  }

  &__button {
    background: white;
    border: none;
    padding: 3vw 6vw;
    margin-top: 3vw;
    color: black;
    border-radius: 100vw;
    font-size: 3.5vw;
    font-weight: 700;
    cursor: pointer;
  }

  &--secondary &__button {
    background: #e3232c;
    box-shadow: 0 2.5vw 10vw rgba(227, 35, 44, 0.5);
    color: white;
  }

  &__image {
    position: absolute;
    height: 50vw;
    right: -8vw;
    top: -10vw;
    transform: scaleX(-1);

    img {
      height: 100%;
    }
  }

  &__coin {
    height: 6vw;
    width: 6vw;
    display: inline-flex;

    img {
      width: 100%;
      height: 100%;
    }
  }
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 1em;
  border-bottom: 1px solid #ccc;
}

.modal-header h3 {
  margin: 0;
}

.modal-content {
  display: flex;
  flex-direction: column;
}

.content--loading {
  opacity: 0;
}

.invite-selector {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 4vw 4vw 10vw;

  &__center {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__amount {
    font-size: 15vw;
    font-weight: 700;
  }

  &__button {
    display: flex;
    justify-content: center;
    align-items: center;
    background: none;
    border: 3px solid rgba(#9d8181, 0.3);
    width: 15vw;
    height: 15vw;
    border-radius: 50%;
    cursor: pointer;

    svg {
      fill: #d2b2b2;
    }

    &--disabled {
      cursor: not-allowed;

      svg {
        opacity: 0.2;
      }
    }
  }
}

.invite-selector span {
  margin: 0 1em;
  font-size: 4vw;
  font-weight: 500;
}

.invite-options {
  display: flex;
  justify-content: center;
  gap: 3vw;
  margin-bottom: 1em;
}

.invite-options button {
  background: #37302f;
  padding: 2vw 5vw;
  border-radius: 4vw;
  font-size: 4.5vw;
  font-weight: 700;
  border: 1px solid transparent;
}

.invite-options .active {
  border: 1px solid #ffffff33;
  background: transparent;
  color: white;
}

.info {
  padding: 4vw 0;
  margin-top: 10vw;
  border-top: 1px solid #3a3836;

  &__item {
    display: flex;
    padding: 2vw 0;
  }

  &__title {
    font-weight: 500;
  }

  &__value {
    margin-left: auto;
    font-weight: 700;
  }

  &__coin {
    height: 6vw;
    margin-left: 2.5vw;
  }
}

.buy-button {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 4vw 8vw;
  margin-top: 4vw;

  width: 100%;
  top: 2vw;
  right: 2vw;

  background: #e3232c;
  box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
  border-radius: 100vw;

  color: white;
  font-size: 4vw;
  font-weight: 700;
  border: 1px solid transparent;

  &:disabled {
    background: transparent;
    box-shadow: none;
    border: 1px solid rgba(255, 255, 255, 0.2);
    color: rgba(255, 255, 255, 0.5);
    cursor: not-allowed;
  }
}

.loading {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4.5vw;
  font-weight: 700;
  color: rgba(white, 0.5);

  &__spinner {
    width: 30vw;
    margin: 35vw 0 35vw;
  }
}
</style>
