<template>
  <div class="referrals-list">
    <div class="title">
      Your invitees <span>{{ user?.referrals_count }}</span>
    </div>
    <div class="content">
      <div
        class="referral"
        v-for="(referral, index) in referrals"
        :key="referral.referral_id"
      >
        <img
          class="avatar"
          :src="getAvatar(referral.referral_id)"
          alt="Avatar"
        />
        <div class="name">Ref #{{ index + 1 }}</div>
        <div class="score">+{{ referral.referrer_total_score }}</div>
        <picture>
          <source srcset="img/coin.avif" type="image/avif" />
          <source srcset="img/coin.png" type="image/png" />
          <img class="coin" src="img/coin.png" alt="Coin" />
        </picture>
      </div>

      <div v-if="!referrals.length && !isFetching" class="no-referrals">
        <img src="../assets/group.svg" alt="No referrals" />
        <span>No invitees here just yet...</span>
      </div>
    </div>
    <div class="loading" v-if="isFetching">
      <LoadingSpinner class="loading__spinner" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed, onMounted, onBeforeUnmount, watch } from 'vue';
import useStore from '@/store';
import { minidenticon } from 'minidenticons';
import LoadingSpinner from './LoadingSpinner.vue';

const store = useStore();

const lastReferralsCount = ref(0);

const user = computed(() => store.user);
const referrals = computed(() => store.referrals);
const shouldResetReferrals = computed(() => store.shouldResetReferrals);
const isFetching = computed(() => store.isFetchingReferrals);

const fetchMore = async () => {
  if (isFetching.value || lastReferralsCount.value === referrals.value.length) {
    return;
  }
  lastReferralsCount.value = referrals.value.length;
  await store.fetchReferrals();
  handleScroll();
};

const handleScroll = () => {
  const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
  const windowHeight = window.innerHeight;
  const documentHeight = document.documentElement.scrollHeight;

  if (documentHeight - (scrollTop + windowHeight) < windowHeight) {
    fetchMore();
  }
};

const getAvatar = (id: number) => {
  return (
    'data:image/svg+xml;utf8,' +
    encodeURIComponent(minidenticon(id.toString(), 90, 90))
  );
};

watch(
  shouldResetReferrals,
  async (newValue, oldValue) => {
    if (newValue !== oldValue) {
      await fetchMore();
    }
  },
  { immediate: true },
);

onMounted(() => {
  const app = document.getElementById('app');
  if (app) {
    app.addEventListener('scroll', handleScroll);
  } else {
    console.error('App element not found');
  }
});

onBeforeUnmount(() => {
  const app = document.getElementById('app');
  if (app) {
    app.removeEventListener('scroll', handleScroll);
  } else {
    console.error('App element not found');
  }
});
</script>

<style lang="scss" scoped>
$background: #1d1c1b;

.referrals-list {
  position: relative;
  border-radius: 3vw;
  background: $background;
  width: 100%;
  font-size: 4vw;
  font-weight: 600;
}

.title {
  padding: 7vw 5vw 4vw;
  span {
    color: #9d8181;
  }
}

.referral {
  display: flex;
  align-items: center;
  gap: 3vw;
  padding: 5vw;

  &:not(:last-child) {
    border-bottom: 1px solid #2d2c2a;
  }
}

.avatar {
  width: 8vw;
  height: 8vw;
  border-radius: 100vw;
}

.score {
  margin-left: auto;
  color: #ff676e;
}

.coin {
  width: 7vw;
  height: 7vw;
}

.no-referrals {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-bottom: 5vw;
  color: #9d8181;
  font-size: 4vw;

  img {
    width: 25vw;
    margin: 1vw 0 4vw;
  }
}

.loading {
  display: flex;
  justify-content: center;
  width: 100%;

  &__spinner {
    width: 30vw;
    height: 30vw;
  }
}
</style>
