<script setup lang="ts">
import { computed } from 'vue';
import { defineProps } from 'vue';
import { Task } from '@/types/tasks';
import useStore from '@/store';
import ArrowRightIcon from './icons/ArrowRightIcon.vue';
import CheckIcon from './icons/CheckIcon.vue';
import { PageName } from '@/types';
import { useHapticFeedback } from '@/directives/haptic';
import { useRouter } from 'vue-router';

const props = defineProps<{
  task: Task;
  next: boolean;
}>();

const router = useRouter();
const store = useStore();

const completed = computed(() =>
  (store.completedTasks ?? []).some(task => task.task_id === props.task.id),
);

const coverSrc = computed(() => `/img/tasks/tasks/${props.task.image}`);

const openTask = () => {
  useHapticFeedback('soft');
  router.push({ name: PageName.Task, params: { id: props.task.id } });
};
</script>

<template>
  <div
    :class="{
      'task-in-list': true,
      'task-in-list--completed': completed,
      'task-in-list--next': next,
    }"
    @click="openTask"
  >
    <div v-if="next" class="blackout"></div>
    <div class="cover">
      <picture>
        <source :srcset="coverSrc + '.avif'" type="image/avif" />
        <source :srcset="coverSrc + '.jpg'" type="image/jpeg" />
        <img :src="coverSrc + '.jpg'" alt="Quest in list cover" />
      </picture>
      <div v-if="next" class="next-task-label">Your next quest</div>
    </div>
    <div class="content" ref="content">
      <div class="info">
        <div class="text">
          <span
            :class="{
              title: true,
              'title--multiline': task.multilineTitle,
            }"
            >{{ task ? task.title : ''
            }}{{ task.multilineTitle ? '   ' : '' }}</span
          >
          <br v-if="!task.multilineTitle" />
          <div class="reward">
            <picture>
              <source srcset="img/coin.avif" type="image/avif" />
              <source srcset="img/coin.png" type="image/png" />
              <img class="coin" src="img/coin.png" alt="Coin" />
            </picture>
            <span>+{{ task.reward }}</span>
          </div>
        </div>
      </div>
      <div v-if="!next" class="status">
        <CheckIcon v-if="completed" />
        <ArrowRightIcon v-else />
      </div>
    </div>
    <button v-if="next" class="start-button">Start quest now</button>
    <div v-if="next" class="border"></div>
  </div>
</template>

<style lang="scss" scoped>
$border-radius: 4vw;

.task-in-list {
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: $border-radius;
  background: #1d1c1b;
  overflow: hidden;
  cursor: pointer;

  &--next {
    background: #31302e;
    background-image: url(../assets/pattern.svg);
  }
}

.blackout {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 1) 0%,
    rgba(0, 0, 0, 0.4) 100%
  );
}

.cover {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 22vw;

  .task-in-list--next & {
    height: 28vw;
  }

  picture,
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  .next-task-label {
    position: absolute;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    border-top-right-radius: 6vw;
    padding: 1.5vw 4vw;
    font-size: 4vw;
    font-weight: 500;
    background: #31302e;
    background-image: url(../assets/pattern.svg);
  }
}

.content {
  position: relative;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 600;
  font-size: 3.5vw;

  .task-in-list--completed & {
    background: rgba(black, 25%);
  }
}

.some-content {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 20vw;
  background: rgba(black, 25%);
  font-size: 3.5vw;

  svg {
    position: absolute;
  }

  div {
    position: relative;
    font-size: 4vw;
    font-weight: 600;
    text-align: center;
    text-transform: uppercase;
    padding: 15vw;
    margin-bottom: 4vw;
  }
}

.info {
  display: flex;
  align-items: center;
  padding: 2.1vw 0 2.1vw 4vw;

  .task-in-list--next & {
    padding: 3vw 0 3vw 4vw;
  }

  .task-in-list--completed & {
    opacity: 0.2;
  }
}

.title {
  white-space: nowrap;

  &--multiline {
    white-space: pre-wrap;
  }

  .task-in-list--next & {
    white-space: pre-wrap;
    line-height: 140%;
    font-size: 5.5vw;
  }
}

.reward {
  vertical-align: top;
  display: inline-flex;
  align-items: center;
  gap: 1vw;

  .task-in-list--next & {
    font-size: 4.5vw;
  }
}

.coin {
  width: 4vw;
  height: 4vw;
}

.status {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 8vw;
  height: 8vw;
  margin: 3.5vw;
  background: rgba(black, 25%);
  border-radius: 50%;

  svg {
    height: 3.5vw;
    width: auto;
    max-width: 4vw;
    fill: white;
  }
}

.start-button {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 1vw 4vw 4vw 4vw;
  padding: 4vw 0;
  z-index: 1000;

  background: #e3232c;
  box-shadow: 0 1vw 8vw rgba(227, 35, 44, 0.5);
  border-radius: 100vw;

  color: white;
  text-shadow: 0 0 7vw white, 0 0 7vw rgba(255, 255, 255, 0.5);
  font-size: 4vw;
  font-weight: 800;
  border: 1px solid transparent;
}

.border {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: $border-radius;
  border: 0.4vw solid rgba(white, 0.3);
  pointer-events: none;
}
</style>
