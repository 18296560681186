<template>
  <svg
    width="19"
    height="19"
    viewBox="0 0 19 19"
    fill="white"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M18.0669 9.42328C18.0669 14.3002 14.1133 18.2538 9.23639 18.2538C4.35944 18.2538 0.405884 14.3002 0.405884 9.42328C0.405884 4.54632 4.35944 0.592773 9.23639 0.592773C14.1133 0.592773 18.0669 4.54632 18.0669 9.42328ZM13.3283 7.99617C13.7594 7.5651 13.7594 6.8662 13.3283 6.43514C12.8973 6.00407 12.1984 6.00407 11.7673 6.43514L7.76464 10.4378L6.70546 9.37864C6.2744 8.94757 5.5755 8.94757 5.14444 9.37864C4.71337 9.80971 4.71337 10.5086 5.14444 10.9397L6.98412 12.7794C7.41519 13.2104 8.11409 13.2104 8.54515 12.7794L13.3283 7.99617Z"
    />
  </svg>
</template>
