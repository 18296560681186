<template>
  <svg
    width="24"
    height="4"
    viewBox="0 0 24 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.2 0.199951L1.8 0.199951C0.805888 0.199951 0 1.00584 0 1.99995C0 2.99406 0.805888 3.79995 1.8 3.79995L10.2 3.79995L13.8 3.79995L22.2 3.79995C23.1941 3.79995 24 2.99406 24 1.99995C24 1.00584 23.1941 0.199951 22.2 0.199951L13.8 0.199951L10.2 0.199951Z"
    />
  </svg>
</template>
