<template>
  <div class="tasks-menu__wrapper">
    <div class="tasks-menu__container">
      <template v-for="(type, index) in types" :key="type">
        <div
          v-haptic="'soft'"
          :class="{
            'tasks-menu__item': true,
            'tasks-menu__item--active': active === type,
          }"
          @click="changeActive(type)"
        >
          <MapIcon v-if="type === TasksListType.Map" />
          <ListIcon v-if="type === TasksListType.List" />
        </div>
        <div v-if="index !== types.length - 1" class="tasks-menu__divider" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { defineProps } from 'vue';
import MapIcon from './icons/MapIcon.vue';
import ListIcon from './icons/ListIcon.vue';
import { TasksListType } from '@/types/tasks';
import { useRoute, useRouter } from 'vue-router';
import { PageName } from '@/types';
import useStore from '@/store';

defineProps<{
  active: TasksListType;
}>();

const router = useRouter();
const route = useRoute();

const types = [TasksListType.Map, TasksListType.List];
const store = useStore();
function changeActive(type: TasksListType) {
  store.setTypeOfTasksView(type);
  if (route.name === PageName.World && type === TasksListType.List) {
    router.replace({
      name: PageName.Location,
      params: { locationId: 'null' },
    });
  } else if (
    route.name === PageName.Location &&
    route.params.locationId === 'null'
  ) {
    router.replace({
      name: PageName.World,
    });
  }
}
</script>

<style lang="scss" scoped>
@keyframes menu-animation {
  0% {
    backdrop-filter: blur(5vw) opacity(0);
    opacity: 0;
  }
  100% {
    backdrop-filter: blur(5vw) opacity(1);
    opacity: 1;
  }
}

.tasks-menu {
  &__wrapper {
    position: fixed;
    display: flex;
    justify-content: center;
    width: 100vw;
    bottom: 27vw;
    left: 0;
    z-index: 1000;
    pointer-events: none;
  }

  &__container {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    background: rgba(black, 0.5);
    backdrop-filter: blur(5vw);
    border-radius: 100vw;
    border: 1px solid rgba(white, 0.2);
    animation: menu-animation 800ms;
    pointer-events: auto;
  }

  &__item {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 3vw 6vw;
    cursor: pointer;

    svg {
      width: 6vw;
      height: 6vw;
      fill: rgba(white, 0.5);
      transition: fill 150ms;
    }

    &--active svg {
      fill: white;
    }
  }

  &__divider {
    width: 1px;
    height: 60%;
    background-color: rgba(white, 0.2);
  }
}
</style>
