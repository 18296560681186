<template>
  <div class="blackout-bottom"></div>
</template>

<style lang="scss" scoped>
.blackout-bottom {
  position: fixed;
  height: 30vh;
  width: 100vw;
  left: 0;
  bottom: 0;
  background: linear-gradient(180deg, rgba(20, 20, 20, 0) 0%, #141414 106.35%);
  z-index: 100;
  pointer-events: none;
}
</style>
