<script setup lang="ts">
import {
  ref,
  defineProps,
  onMounted,
  onUnmounted,
  defineEmits,
  computed,
  watch,
} from 'vue';

const props = defineProps({
  timestamp: {
    type: Date,
    required: true,
  },
  textBefore: {
    type: String,
    default: '',
  },
  textAfter: {
    type: String,
    default: '',
  },
  hideOnComplete: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits<{
  (event: 'timeUpdate', value: number): void;
  (event: 'complete'): void;
}>();

const timeDiff = ref(0);

function updateTimeRemaining() {
  const now = Date.now();
  const targetTime = props.timestamp.getTime();
  let diff = Math.floor((targetTime - now) / 1000);

  if (diff <= 0) {
    diff = 0;
    if (timerInterval !== null) {
      clearInterval(timerInterval);
      timerInterval = null; // Indicates the interval has been cleared
    }
    timeDiff.value = diff;
    emit('complete');
  } else {
    timeDiff.value = diff;
  }

  emit('timeUpdate', diff);
}

const formattedTime = computed(() => {
  const diff = timeDiff.value;
  const days = Math.floor(diff / 86400);
  const hours = String(Math.floor((diff % 86400) / 3600)).padStart(2, '0');
  const minutes = String(Math.floor((diff % 3600) / 60)).padStart(2, '0');
  const seconds = String(diff % 60).padStart(2, '0');
  return days > 0
    ? `${days}d ${hours}:${minutes}:${seconds}`
    : `${hours}:${minutes}:${seconds}`;
});

const displayText = computed(() => {
  return `${props.textBefore} ${formattedTime.value} ${props.textAfter}`;
});

const hideTimer = computed(() => {
  return props.hideOnComplete && timeDiff.value === 0;
});

let timerInterval: ReturnType<typeof setInterval> | null = null;

watch(
  () => props.timestamp,
  () => {
    if (timerInterval !== null) {
      clearInterval(timerInterval);
      timerInterval = null;
    }
    updateTimeRemaining();
    timerInterval = setInterval(updateTimeRemaining, 1000);
  },
);

onMounted(() => {
  updateTimeRemaining();
  timerInterval = setInterval(updateTimeRemaining, 1000);
});

onUnmounted(() => {
  if (timerInterval !== null) {
    clearInterval(timerInterval);
    timerInterval = null;
  }
});
</script>

<template>
  <div class="countdown-timer" aria-live="polite" v-if="!hideTimer">
    {{ displayText }}
  </div>
</template>

<style scoped lang="scss">
.countdown-timer {
  font-family: 'JetBrains Mono', monospace;
}
</style>
