export enum PageName {
  Root = 'Root',
  Login = 'Login',
  Tap = 'Tap',
  Earn = 'Earn',
  Referrals = 'Referrals',
  World = 'World',
  Location = 'Location',
  Task = 'Task',
  TaskSurvey = 'TaskSurvey',
  UnsupportedPlatform = 'UnsupportedPlatform',
}
