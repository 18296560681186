<template>
  <div class="banner" @click="goToReferralsPage" v-haptic="'soft'">
    <div v-if="fullLayout" class="banner__header">
      <picture class="banner__image">
        <source srcset="img/invite.avif" type="image/avif" />
        <source srcset="img/invite.png" type="image/png" />
        <img src="img/invite.png" alt="Daily check-in" />
      </picture>
      <div class="banner__header-center">
        <div class="banner__title">Refer & earn</div>
        <div class="banner__invites-available">
          {{ invitesAvailable }} Invites available
        </div>
      </div>
      <div class="banner__arrow-button">
        <ArrowChevronRightIcon />
      </div>
    </div>
    <div class="banner__content">
      <div class="banner__text" v-if="fullLayout">
        Invite samurais and get 10% of their earnings.
      </div>
      <div class="banner__progress">
        <div class="banner__progress-bar">
          <div
            :style="{ width: completedPart + '%' }"
            class="banner__progress-bar-line"
          ></div>
        </div>
        <div class="banner__progress-level">Tier {{ currentTier }}</div>
        <div class="banner__invite">
          <template v-if="actualTier + 1 < REFERRAL_TIERS.length">
            {{ currentTierReferralsLeft }} invites till
            <span>{{ getReward(currentTier) }}</span>
            <CoinIcon />
          </template>
          <template v-else> You have achieved the last tier </template>
        </div>
      </div>
      <div class="banner__buttons">
        <LoadingSpinner v-if="loading" class="banner__loading-spinner" />
        <template v-else>
          <button
            v-if="showClaimButton && fullLayout"
            class="claim-button"
            @click="claim"
          >
            Claim tier reward
            <CoinIcon />
          </button>
          <button v-else class="banner__button" @click="openModal">
            See tiers & rewards
          </button>
        </template>
      </div>
    </div>
  </div>

  <SModal v-model:show="showModal" v-bind="modalSettings">
    <SModalCard class="modal referral-tiers-modal">
      <template #title>
        <div class="modal__header">
          <picture class="modal__image">
            <source srcset="img/invite.avif" type="image/avif" />
            <source srcset="img/invite.png" type="image/png" />
            <img src="img/invite.png" alt="Daily check-in" />
          </picture>
          <div class="modal__title">Invite & earn tiers</div>
          <div class="modal__description">
            Invite people, upgrade tiers, earn rewards
          </div>
        </div>
      </template>
      <div
        :class="{
          modal__content: true,
          'modal__content--loading': loading,
        }"
      >
        <div v-if="user" class="list">
          <div
            v-for="tier in REFERRAL_TIERS.length - 1"
            :key="tier"
            :class="{
              list__item: true,
              'list__item--current': tier === currentTier,
              'list__item--claimed': tier <= claimedTier,
            }"
          >
            <div class="list__text">
              <div class="list__tier">Tier {{ tier }}</div>
              <div class="list__information">
                {{ getTierInformationText(tier) }}
              </div>
            </div>
            <div class="list__coin">
              <CoinIcon />
              <CheckCircleIcon
                v-if="tier <= user?.referral_tier"
                class="list__check"
              />
            </div>
            <div class="list__progress-bar">
              <div
                :style="{ width: getTierCompletedPart(tier) + '%' }"
                class="list__progress-bar-line"
              />
            </div>
            <div class="list__reward">
              {{ getReward(tier) }}
            </div>
          </div>
        </div>
        <BlackoutBottom class="blackout" />
        <div class="modal__buttons">
          <button v-if="showClaimButton" class="claim-button" @click="claim">
            Claim tier reward
            <CoinIcon class="claim-button__coin" />
          </button>
          <button v-else class="modal__button" @click="goToReferralsPage">
            Invite
          </button>
        </div>
      </div>
      <div v-if="loading" class="loading">
        <LoadingSpinner class="loading__spinner" />
      </div>
    </SModalCard>
  </SModal>
</template>

<script setup lang="ts">
import { ref, computed, defineProps } from 'vue';
import useStore from '@/store';
import { useRouter } from 'vue-router';
import ArrowChevronRightIcon from './icons/ArrowChevronRightIcon.vue';
import { REFERRAL_TIERS, REWARD_PER_REF } from '@/const';
import { PageName } from '@/types';
import CoinIcon from './icons/CoinIcon.vue';
import BlackoutBottom from './BlackoutBottom.vue';
import CheckCircleIcon from './icons/CheckCircleIcon.vue';
import LoadingSpinner from './LoadingSpinner.vue';

defineProps({
  fullLayout: {
    type: Boolean,
    default: true,
  },
});

const store = useStore();

const showModal = ref(false);
const loading = ref(false);
const modalSettings = {
  closeOnOverlayClick: true,
  closeOnEsc: true,
  focusTrap: true,
  eager: false,
};

const user = computed(() => store.user);
const invitesAvailable = computed(() =>
  user.value ? user.value.max_referrals_count - user.value.referrals_count : 0,
);

const actualTier = computed(() => {
  if (!user.value) return 0;
  return (
    REFERRAL_TIERS.filter(
      referrals => user.value && user.value.referrals_count >= referrals,
    ).length - 1
  );
});

const claimedTier = computed(() => (user.value ? user.value.referral_tier : 0));

const currentTier = computed(() =>
  user.value
    ? Math.min(user.value.referral_tier + 1, REFERRAL_TIERS.length - 1)
    : 0,
);

const currentTierReferralsLeft = computed(() =>
  user.value
    ? Math.max(
        REFERRAL_TIERS[currentTier.value] - user.value.referrals_count,
        0,
      )
    : 0,
);

const completedPart = computed(() => getTierCompletedPart(currentTier.value));

const showClaimButton = computed(() => actualTier.value !== claimedTier.value);

const router = useRouter();

const claim = async (event: Event) => {
  event.stopPropagation();
  try {
    loading.value = true;
    await store.claimReferralReward();
  } finally {
    loading.value = false;
  }
};

const openModal = (event: Event) => {
  event.stopPropagation();
  showModal.value = true;
};

const getReward = (tier: number) => {
  if (tier >= REFERRAL_TIERS.length) return 0;
  return REFERRAL_TIERS[tier] * REWARD_PER_REF;
};

const getTierAmounts = (tier: number) => {
  const referralsCount = user.value ? user.value.referrals_count : 0;
  const referralsForTier = REFERRAL_TIERS[tier];
  const referralsCompleted = Math.min(referralsCount, referralsForTier);
  const referralsLeft = Math.max(referralsForTier - referralsCount, 0);
  return {
    referralsCount,
    referralsCompleted,
    referralsForTier,
    referralsLeft,
  };
};

const getTierInformationText = (tier: number) => {
  const amounts = getTierAmounts(tier);
  if (tier <= actualTier.value + 1) {
    return `${amounts.referralsCompleted}/${amounts.referralsForTier} invites completed`;
  } else {
    return `${amounts.referralsLeft} invites to complete`;
  }
};

const goToReferralsPage = () => {
  router.push({ name: PageName.Referrals });
  showModal.value = false;
};

const getTierCompletedPart = (tier: number) => {
  const amounts = getTierAmounts(tier);
  return (amounts.referralsCompleted / amounts.referralsForTier) * 100;
};
</script>

<style lang="scss" scoped>
.banner {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 7vw;
  border-radius: 4vw;
  background: #1d1c1b;

  &__header {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 4vw 4vw 0 0;
    background: #31302e;
    background-image: url(../assets/pattern.svg);
    cursor: pointer;

    &-center {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      margin-left: 20vw;
    }
  }

  &__image {
    position: absolute;
    width: 18vw;
    left: -1vw;
    bottom: 0;

    img {
      width: 100%;
    }
  }

  &__title {
    color: white;
    font-size: 4.25vw;
    font-weight: 600;
    line-height: 120%;
  }

  &__invites-available {
    display: flex;
    gap: 1vw;
    font-size: 3.5vw;
    font-weight: 500;
    color: #8b8b8b;
  }

  &__time {
    background: rgba(black, 0.2);
    border-radius: 1.5vw;
    font-size: 3vw;
    font-weight: 700;
    padding: 0.5vw 2vw;
    text-transform: uppercase;
  }

  &__arrow-button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 8vw;
    height: 8vw;
    margin: 3.5vw;
    background: rgba(black, 25%);
    border-radius: 50%;

    svg {
      height: 3.5vw;
      width: auto;
      max-width: 4vw;
      fill: white;
    }
  }

  &__content {
    display: flex;
    flex-direction: column;
    padding: 2.5vw 4vw;
    background: #1d1c1b;
    border-radius: 4vw;
  }

  &__text {
    line-height: 140%;
    font-size: 3.5vw;
    font-weight: 500;
    color: rgba(white, 0.5);

    span {
      color: white;
      font-weight: 700;
    }
  }

  &__progress {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    margin-top: 4vw;

    &-bar {
      position: relative;
      width: 100%;
      height: 1.5vw;
      margin-bottom: 2vw;
      background: rgba(white, 0.1);
      border-radius: 1vw;
      overflow: hidden;

      &-line {
        height: 100%;
        border-radius: 1vw;
        background: linear-gradient(
          90deg,
          #e3232c 0%,
          #ff8f94 69.99%,
          #ffffff 99.5%
        );
      }
    }

    &-day {
      font-size: 3.5vw;
      font-weight: 700;
      color: white;
    }

    &-earned {
      font-size: 3.5vw;
      font-weight: 500;
    }
  }

  &__invite {
    display: flex;
    gap: 1vw;
    font-size: 3.5vw;
    color: #8b8b8b;

    span {
      color: white;
    }
  }

  &__buttons {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 14vw;
    margin: 4vw 0 1vw;

    .claim-button {
      height: 100%;
      padding: 0;
      margin: 0;
    }
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;

    background: #3d353566;
    border-radius: 100vw;

    color: white;
    font-size: 4vw;
    font-weight: 700;
    border: 1px solid transparent;
  }

  &__loading-spinner {
    width: 20vw;
    height: 20vw;
    margin: -5vw;
  }
}

.modal {
  background: #31302e;

  &__header {
    position: relative;
  }

  &__image {
    display: inline-flex;
    width: 25vw;

    img {
      width: 100%;
    }
  }

  &__description {
    font-size: 3.5vw;
    color: rgba(white, 0.5);
  }

  &__content {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 0 3vw;
    padding-bottom: 20vw;

    &--loading {
      opacity: 0;
    }
  }

  &__buttons {
    position: absolute;
    width: calc(100% - 6vw);
    bottom: 3vw;
  }

  &__button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 4vw 8vw;

    width: 100%;
    margin: 4vw 0 1vw;

    background: #e3232c;
    box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
    border-radius: 100vw;

    color: white;
    font-size: 4vw;
    font-weight: 700;
    border: 1px solid transparent;
  }
}

.list {
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
  padding: 2vw 0;

  &__item {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    gap: 1.5vw;
    padding: 2vw 3vw;
    border-radius: 4vw;
    background: #161514;

    &--current,
    &--claimed {
      background: #272423;
    }

    &--current {
      border: 1px solid white;
    }
  }

  &__text {
    min-width: 70%;
  }

  &__tier {
    font-size: 3.5vw;
    font-weight: 500;
    color: white;
  }

  &__item--claimed &__tier {
    opacity: 0.5;
  }

  &__coin {
    position: relative;
    width: 8vw;
    height: 8vw;
    margin: 2vw 0;

    picture {
      width: 100%;
      height: 100%;
    }
  }

  &__item--claimed:not(&__item--current) &__coin picture {
    opacity: 0.5;
  }

  &__check {
    position: absolute;
    width: 4.5vw;
    height: 4.5vw;
    bottom: -1vw;
    right: -1vw;
    border-radius: 100vw;
    background: #1d1c1b;
    fill: white;
  }

  &__reward {
    background: #e3232c;
    border-radius: 100vw;
    font-size: 3vw;
    font-weight: 600;
    padding: 0 2vw;
  }

  &__item--claimed &__reward {
    background: rgba(white, 0.12);
    color: white;
  }
  &__item--current &__reward,
  &__item--combo-day &__reward {
    background: white;
    color: black;
  }

  &__progress-bar {
    position: relative;
    min-width: 70%;
    flex: 1;
    height: 1.5vw;
    background: rgba(white, 0.1);
    border-radius: 1vw;
    overflow: hidden;

    &-line {
      height: 100%;
      border-radius: 1vw;
      background: linear-gradient(
        90deg,
        #e3232c 0%,
        #ff8f94 69.99%,
        #ffffff 99.5%
      );
    }
  }
}

.blackout {
  position: absolute;
  width: 100%;
  height: 30vw;
  z-index: auto;
}

.claim-button {
  width: 100%;
  padding: 4vw 8vw;

  margin: 4vw 0 1vw;

  background: #e3232c;
  box-shadow: 0 1vw 5vw rgba(227, 35, 44, 0.5);
  border-radius: 100vw;

  color: white;
  font-size: 4vw;
  font-weight: 700;
  border: 1px solid transparent;
}

.loading {
  position: absolute;
  width: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 4.5vw;
  font-weight: 700;
  color: rgba(white, 0.5);

  &__spinner {
    width: 30vw;
    margin: 35vw 0 35vw;
  }
}
</style>

<style lang="scss">
.s-modal__root .referral-tiers-modal {
  position: relative;
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  background: #31302e;
  background-image: url(../assets/pattern.svg);

  .s-modal-card__header {
    align-items: flex-start;

    .s-button {
      background-color: rgba(white, 0.2);

      svg {
        fill: white;
      }
    }
  }
  .s-modal-card__content {
    background: #1d1c1b;
    padding: 4vw 0;
    border-radius: 4vw 4vw 0 0;
  }
}
</style>
